export const DEVICE_STATUSES = [
  {
    label: 'Available',
    value: 'available',
  },
  {
    label: 'Reserved',
    value: 'reserved',
  },
  {
    label: 'Maintenance',
    value: 'maintenance',
  },
]

export const DEVICE_MODE_TYPES = [
  {
    label: 'Asset',
    value: 'rental',
  },
  {
    label: 'Storage',
    value: 'storage',
  },
  {
    label: 'Delivery',
    value: 'delivery',
  },
  {
    label: 'Service',
    value: 'service',
  },
  {
    label: 'Vending',
    value: 'vending',
  },
]

export const DEVICE_TYPES = [
  {
    label: 'Linka',
    value: 'linka',
  },
  {
    label: 'Spintly',
    value: 'spintly',
  },
  {
    label: 'Ojmar',
    value: 'ojmar',
  },
  {
    label: 'Gantner',
    value: 'gantner',
  },
  {
    label: 'Harbor',
    value: 'harbor',
  },
  {
    label: 'DC Locker',
    value: 'dclock',
  },
  {
    label: 'Virtual Locker',
    value: 'virtual',
  },
  {
    label: 'Kerong',
    value: 'kerong',
  },
]
