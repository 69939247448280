import { Grid, Box } from '@mui/material'
import img1 from '../../assets/images/backgrounds/login-bg.png'
import { useAuth } from 'resources'
import { ResetPasswordStyles } from './styles'
import { ResetPasswordForm, PageContainer, Image } from 'components'
import { RESET_PASSWORD_PAGE } from '../../constants'

const ResetPassword = () => {
  const { SX, STYLES } = ResetPasswordStyles()

  const { configureAmplify } = useAuth()

  const initAmplify = () => {
    configureAmplify()
  }

  initAmplify()

  return (
    <PageContainer
      title={RESET_PASSWORD_PAGE.TITLE}
      description={RESET_PASSWORD_PAGE.DESCRIPTION}
    >
      <Grid container spacing={0} sx={SX.GRID_CONTAINER}>
        <Grid item xs={12} sm={12} lg={6} sx={SX.GRID_ITEM}>
          <Box sx={SX.BOX_IMAGE_CONTAINER}>
            <Box sx={SX.BOX_IMAGE}>
              <Image src={img1} alt="bg" style={STYLES.IMG} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item xs={12} lg={9} xl={6}>
              <ResetPasswordForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default ResetPassword
