import JsBarcode from 'jsbarcode'

const BarcodeGenerator = () => {
  const onClickDownload = (fileName, fileData) => {
    const link = document.createElement('a')
    link.href = fileData
    link.download = `${fileName}-Barcode.png`
    link.click()
  }

  const generateBarcode = (name, data) => {
    const canvas = document.createElement('canvas')
    JsBarcode(canvas, data, {
      format: 'CODE128',
      lineColor: '#000000',
      width: 2,
      height: 100,
      displayValue: true,
    })
    const fileData = canvas.toDataURL('image/png')
    onClickDownload(name, fileData)
  }

  const generateBarcodeDataURL = async (data) => {
    const canvas = document.createElement('canvas')
    JsBarcode(canvas, data, {
      format: 'CODE128',
      lineColor: '#000000',
      width: 2,
      height: 100,
      displayValue: true,
    })
    return canvas.toDataURL('image/png')
  }

  return { generateBarcode, generateBarcodeDataURL }
}

export default BarcodeGenerator
