import { Button, Card, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { saveAs } from 'file-saver'
import { ConfirmDialog, NotificationDialog } from 'components/Dialog'
import { createCsvBlob } from 'utils/helpers'
import {
  DeviceColumnValues,
  DeviceHeaders,
  DeviceLabels,
  ProductColumnValues,
  ProductHeaders,
  ProductLabels,
  EventColumnValues,
  EventHeaders,
  EventLabels,
  SizeColumnValues,
  SizeHeaders,
  SizeLabels,
  LocationColumnValues,
  LocationHeaders,
  LocationLabels,
  ProductGroupColumnValues,
  ProductGroupHeaders,
  ProductGroupLabels,
  ConditionColumnValues,
  ConditionHeaders,
  ConditionLabels,
  UserColumnValues,
  UserHeaders,
  UserLabels,
  GroupColumnValues,
  GroupHeaders,
  GroupLabels,
  MemberColumnValues,
  MemberHeaders,
  MemberLabels,
  ReservationColumnValues,
  ReservationHeaders,
  ReservationLabels,
  MembershipColumnValues,
  MembershipHeaders,
  MembershipLabels,
  IssueColumnValues,
  IssueHeaders,
  IssueLabels,
} from './constants'
interface IMultiSelectToolbarProps {
  itemsSelectedData?: any
  itemsSelected: number
  handleAction?: () => void
  setAction?: React.Dispatch<React.SetStateAction<string>>
  actionsAllowed?: string[]
  show?: boolean
  model?: string
}

const MultiSelectToolbar = ({
  itemsSelectedData,
  itemsSelected,
  handleAction,
  setAction,
  actionsAllowed = ['delete'],
  show = true,
  model,
}: IMultiSelectToolbarProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const [notificationMessage, setNotificationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const onConfirm = () => {
    handleAction?.()
    closeDialog()
  }

  const convertArrayOfObjectsToCSV = (
    arrayData,
    headers,
    labels,
    model,
    setDetailsHelper,
  ) => {
    const csvRows: any = []
    const csvHeaderLabels = headers.map((key) => labels[key])
    csvRows.push(csvHeaderLabels)

    for (const obj of arrayData) {
      const data = setDetailsHelper(obj)
      const ParsedData = headers.map((key) => data[key])
      csvRows.push(ParsedData)
    }

    const csvContent = csvRows.map((row) => row.join(',')).join('\n')
    const csvBlob = createCsvBlob(csvContent)

    saveAs(csvBlob, `${model}_export.csv`)
    setNotificationMessage({
      isOpen: true,
      message: `Exported ${itemsSelected} item${
        itemsSelected > 1 ? 's' : ''
      } successfully`,
    })
  }

  const handleExportDevices = () => {
    for (const obj of itemsSelectedData) {
      if (obj.hardware_type !== itemsSelectedData[0].hardware_type) {
        setNotificationMessage({
          isOpen: true,
          message: `Cannot export items with different hardware types`,
        })
        return
      }
    }

    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      DeviceHeaders,
      DeviceLabels,
      model,
      DeviceColumnValues,
    )
  }
  const handleExportProduct = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      ProductHeaders,
      ProductLabels,
      model,
      ProductColumnValues,
    )
  }
  const handleExportEvents = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      EventHeaders,
      EventLabels,
      model,
      EventColumnValues,
    )
  }
  const handleExportSize = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      SizeHeaders,
      SizeLabels,
      model,
      SizeColumnValues,
    )
  }
  const handleExportLocation = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      LocationHeaders,
      LocationLabels,
      model,
      LocationColumnValues,
    )
  }
  const handleExportProductGroup = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      ProductGroupHeaders,
      ProductGroupLabels,
      model,
      ProductGroupColumnValues,
    )
  }
  const handleExportCondition = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      ConditionHeaders,
      ConditionLabels,
      model,
      ConditionColumnValues,
    )
  }
  const handleExportUser = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      UserHeaders,
      UserLabels,
      model,
      UserColumnValues,
    )
  }

  const handleExportGroup = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      GroupHeaders,
      GroupLabels,
      model,
      GroupColumnValues,
    )
  }

  const handleExportMember = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      MemberHeaders,
      MemberLabels,
      model,
      MemberColumnValues,
    )
  }

  const handleExportReservation = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      ReservationHeaders,
      ReservationLabels,
      model,
      ReservationColumnValues,
    )
  }

  const handleExportMembership = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      MembershipHeaders,
      MembershipLabels,
      model,
      MembershipColumnValues,
    )
  }

  const handleExportIssues = () => {
    convertArrayOfObjectsToCSV(
      itemsSelectedData,
      IssueHeaders,
      IssueLabels,
      model,
      IssueColumnValues,
    )
  }

  const closeDialog = () => {
    setConfirmationMessage({ isOpen: false, message: '' })
    setNotificationMessage({ isOpen: false, message: '' })
  }
  const editAction = () => {
    setAction?.('edit')
  }
  const deleteAction = () => {
    setAction?.('delete')
    setConfirmationMessage({
      isOpen: true,
      message: `Are you sure you want to delete ${itemsSelected} item${
        itemsSelected > 1 ? 's' : ''
      }?`,
    })
  }
  const maintenanceAction = () => {
    setAction?.('putUnderMaintenance')
    setConfirmationMessage({
      isOpen: true,
      message: `Are you sure you want to switch ${itemsSelected} device${
        itemsSelected > 1 ? 's' : ''
      } to maintenance mode?`,
    })
  }
  const endAction = () => {
    setAction?.('end')
    setConfirmationMessage({
      isOpen: true,
      message: `Are you sure you want to end ${itemsSelected} transaction${
        itemsSelected > 1 ? 's' : ''
      }?`,
    })
  }

  const handleExportItems = () => {
    switch (model) {
      case 'device':
        handleExportDevices()
        break
      case 'product':
        handleExportProduct()
        break
      case 'event':
        handleExportEvents()
        break
      case 'size':
        handleExportSize()
        break
      case 'location':
        handleExportLocation()
        break
      case 'product-group':
        handleExportProductGroup()
        break
      case 'condition':
        handleExportCondition()
        break
      case 'user':
        handleExportUser()
        break
      case 'group':
        handleExportGroup()
        break
      case 'member':
        handleExportMember()
        break
      case 'reservation':
        handleExportReservation()
        break
      case 'subscription':
        handleExportMembership()
        break
      case 'issue':
        handleExportIssues()
        break
      default:
        break
    }
  }

  return (
    <>
      {show && (
        <>
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: '5px',
            }}
          >
            <Typography color="text.secondary">
              {itemsSelected}{' '}
              {itemsSelected > 1 ? 'items selected' : 'item selected'}
            </Typography>
            <Stack direction="row" spacing={2}>
              {actionsAllowed.includes('export') && (
                <Button variant="contained" onClick={handleExportItems}>
                  Export
                </Button>
              )}

              {actionsAllowed.includes('edit') && (
                <Button variant="contained" onClick={editAction}>
                  Edit
                </Button>
              )}

              {actionsAllowed.includes('delete') && (
                <Button variant="contained" onClick={deleteAction}>
                  Delete
                </Button>
              )}

              {actionsAllowed.includes('putUnderMaintenance') && (
                <Button variant="contained" onClick={maintenanceAction}>
                  Put under maintenance
                </Button>
              )}

              {actionsAllowed.includes('end') && (
                <Button variant="contained" onClick={endAction}>
                  End
                </Button>
              )}
            </Stack>
          </Card>
          <ConfirmDialog
            open={confirmationMessage.isOpen}
            message={confirmationMessage.message}
            onClose={closeDialog}
            onClickConfirm={onConfirm}
            onClickCancel={closeDialog}
            confirmText="Yes"
            cancelText="No"
          />
          <NotificationDialog
            open={notificationMessage.isOpen}
            message={notificationMessage.message}
            onClose={closeDialog}
          />
        </>
      )}
    </>
  )
}

export default MultiSelectToolbar
