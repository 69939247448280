import { Box, Card, Theme, useMediaQuery } from '@mui/material'
import { ConfirmDialog, NotificationDialog, SideBar } from 'components'
import useDialog from 'hooks/useDialog'
import { IDevice, ILocation } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { deviceApi, locationApi } from 'resources'
import LockerWallForm from './components/LockerWallForm'
import { ILocker, ILockerWall } from 'models/LockerWall'
import Wall from './components/Wall'
import { lockerWallApi } from 'resources/locker-wall'
import { useDispatch } from 'react-redux'
import { setLastLocation } from 'redux/locker-wall/Action'
import ColorLegends from './components/ColorLegends'
import WallDetails from './components/WallDetails'
import WallToolbar from './components/WallToolbar'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import { DropdownOption } from 'types'
import useLocations from 'hooks/useLocations'

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

interface ILockerWallProps {
  lastLocation: ILocation | null
}

const LockerWall = ({ lastLocation }: ILockerWallProps) => {
  const [devices, setDevices] = useState<IDevice[]>([])
  const [selectedLocation, setSelectedLocation] = useState<
    ILocation | undefined
  >(lastLocation || undefined)
  const [selectedLocationValue, setSelectedLocationValue] = useState<any>(
    lastLocation ? { id: lastLocation.id, label: lastLocation.name } : null,
  )

  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [lockerWall, setLockerWall] = useState<ILockerWall | undefined>()
  const [selectedWallValue, setSelectedWallValue] = useState<any>(null)
  const [lockerWallMatrix, setLockerWallMatrix] = useState<ILocker[][]>([])
  const [locationWalls, setLocationWalls] = useState<ILockerWall[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mode, setMode] = useState<'add' | 'edit' | ''>('')
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const { getMany: getDevicesAPI } = deviceApi()
  const { getLockerWallsByLocation, remove } = lockerWallApi()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const { loadingLocations, locationsOptions, locations } = useLocations()

  const dispatch = useDispatch()

  const lockerNumbers: DropdownOption[] | undefined = useMemo(() => {
    if (selectedLocation?.devices && lockerWall) {
      const { lockers } = lockerWall
      return selectedLocation?.devices
        ?.filter(
          (device) =>
            device.locker_number &&
            !lockers.some((locker) => locker.id === device.id),
        )
        .map((device) => ({
          value: device.id,
          label: device.locker_number.toString(),
        }))
    }
  }, [selectedLocation, lockerWall])

  const devicesForLocation = useMemo(() => {
    if (selectedLocation && selectedLocation?.devices && lockerWall) {
      return devices.filter((device) =>
        selectedLocation.devices?.some((d) => d.id === device.id),
      )
    }
    return []
  }, [selectedLocation, lockerWall, devices])

  const lockerWallOptions = useMemo(
    () =>
      locationWalls.map((wall) => ({
        value: wall.id,
        label: `${wall.name} (${wall.qty_tall} x ${wall.qty_wide})`,
      })),
    [locationWalls],
  )

  const generateMatrix = () => {
    if (lockerWall) {
      const { qty_tall: rows, qty_wide: columns, lockers, devices } = lockerWall

      const matrix: any[][] = []
      for (let i = 0; i < rows; i++) {
        matrix[i] = []
        for (let j = 0; j < columns; j++) {
          const locker = lockers.find((l) => l.y === i && l.x === j)
          const lockerDevice = devices?.find(
            (device) => device.id === locker?.id,
          )
          if (locker) {
            matrix[i][j] = {
              ...locker,
              ...(lockerDevice && { device: lockerDevice }),
            }
          } else {
            matrix[i][j] = undefined
          }
        }
      }
      return matrix
    }
    return []
  }

  useEffect(() => {
    setLockerWallMatrix(generateMatrix())
  }, [lockerWall])

  const fetchDevices = async (): Promise<void> => {
    try {
      const devices = await getDevicesAPI(1, 10000)
      setDevices(devices.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchLockerWalls = async (locationId = ''): Promise<void> => {
    try {
      setIsLoading(true)
      if (selectedLocation) {
        const lockerWalls = await getLockerWallsByLocation(
          locationId || selectedLocation?.id,
        )
        setLocationWalls(lockerWalls)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectLocation = (event: React.SyntheticEvent, value: any) => {
    const location = locations.find((l) => l.id === value?.value)
    setSelectedLocation(location)
    setSelectedLocationValue(value)
    dispatch(setLastLocation(location))
    if (lockerWall && selectedWallValue) {
      setLockerWall(undefined)
      setSelectedWallValue(null)
    }
  }

  const handleSelectWall = (event: React.SyntheticEvent, value: any) => {
    const wall = locationWalls?.find((w) => w.id === value?.value)
    setLockerWall(wall)
    setSelectedWallValue(value)
  }

  const handleDelete = async () => {
    try {
      if (lockerWall) {
        await remove(lockerWall.id)
        displayMessage('Locker wall deleted successfully!', 'success')
        setLockerWall(undefined)
        setSelectedWallValue(null)
        await fetchLockerWalls()
        setConfirmationMessage(defaultConfirmation)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleConfirmAction = async () => {
    switch (confirmationMessage.action) {
      case 'delete':
        await handleDelete()
        break
      case 'edit':
        setConfirmationMessage(defaultConfirmation)
        setOpenSidebar(true)
        break
      default:
        break
    }
  }

  useEffect(() => {
    fetchLockerWalls()
  }, [selectedLocation])

  useEffect(() => {
    if (
      lockerWall &&
      selectedWallValue &&
      lockerWall.id !== selectedWallValue?.id
    ) {
      setSelectedWallValue({ value: lockerWall.id, label: lockerWall.name })
    }
  }, [lockerWall])

  useEffect(() => {
    if (selectedWallValue && locationWalls.length > 0) {
      const updatedWall = locationWalls.find(
        (wall) => wall.id === selectedWallValue.value,
      )
      if (updatedWall) {
        setLockerWall(updatedWall)
      }
    }
  }, [selectedWallValue, locationWalls])

  useEffect(() => {
    fetchDevices()
  }, [])

  const handleMaintenanceSuccess = async () => {
    await fetchLockerWalls()
    displayMessage('Maintenance status updated successfully!', 'success')
    if (selectedWallValue && locationWalls.length > 0) {
      const updatedWall = locationWalls.find(
        (wall) => wall.id === selectedWallValue.value,
      )
      if (updatedWall) {
        setLockerWall(updatedWall)
      }
    }
  }

  return (
    <Card sx={{ borderRadius: '3px', background: 'inherit' }}>
      <WallToolbar
        setConfirmationMessage={setConfirmationMessage}
        selectedLocation={selectedLocation}
        selectedLocationValue={selectedLocationValue}
        selectedWallValue={selectedWallValue}
        locationOptions={locationsOptions}
        loadingLocations={loadingLocations}
        lockerWallOptions={lockerWallOptions}
        handleSelectLocation={handleSelectLocation}
        handleSelectWall={handleSelectWall}
        setMode={setMode}
        setOpenSidebar={setOpenSidebar}
        lockerWall={lockerWall}
      />
      {lockerWall && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <WallDetails lockerWall={lockerWall} />
          <ColorLegends lockerWall={lockerWall} isMobile={isMobile} />
          <Wall
            lockerWall={lockerWall}
            wallMatrix={lockerWallMatrix}
            lockerNumbers={lockerNumbers || []}
            displayMessage={displayMessage}
            setLockerWall={setLockerWall}
            success={handleMaintenanceSuccess}
            locationWalls={locationWalls}
            devicesForLocation={devicesForLocation}
          />
        </Box>
      )}
      <LoadingTableData
        isLoading={isLoading}
        loadingText={`Fetching locker walls for ${selectedLocation?.name}`}
      />
      <NoData
        condition={!isLoading && !lockerWall && !selectedLocation}
        text="Please select a location to view available locker walls."
      />
      <NoData
        condition={!isLoading && !lockerWall && !!selectedLocation}
        text="No locker wall has been selected. Please select one to proceed."
      />

      {selectedLocation && openSidebar && mode && (
        <SideBar open={openSidebar} onClose={() => setOpenSidebar(false)}>
          <LockerWallForm
            location={selectedLocation}
            lockerWall={lockerWall}
            onClose={() => {
              setMode('')
              setOpenSidebar(false)
            }}
            displayMessage={displayMessage}
            success={async () => {
              await fetchLockerWalls()
              if (selectedWallValue && locationWalls.length > 0) {
                const updatedWall = locationWalls.find(
                  (wall) => wall.id === selectedWallValue.value,
                )
                if (updatedWall) {
                  setLockerWall(updatedWall)
                }
              }
            }}
            mode={mode}
            setLockerWall={setLockerWall}
            devicesForLocation={devicesForLocation}
          />
        </SideBar>
      )}
      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={closeDialog}
        type={dialog.type}
      />
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={handleConfirmAction}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </Card>
  )
}

export default LockerWall
