import { ChangeEvent, useState, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import {
  Table,
  TableBody,
  TablePagination,
  Button,
  SideBar,
  NotificationDialog,
} from 'components'
import { IMemberList, IMember } from 'models'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import { memberApi } from 'resources'
import MemberRow from './TeamMemberRow'
import MemberForm from './TeamMemberForm'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import useColumnFiltering from 'hooks/useColumnFiltering'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import MultiEditForm from './MultiEditForm'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

const TeamMembers = (): React.ReactElement => {
  const [members, setMembers] = useState<IMemberList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [membersBackup, setMembersBackup] = useState<IMember[]>([])
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [open, setOpen] = useState(false)
  const [currentMember, setCurrentMember] = useState<IMember>()
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowData, setSelectedRowData] = useState<any[]>([])
  const [action, setAction] = useState<string>('')
  const [openMultiEditForm, setOpenMultiEditForm] = useState<boolean>(false)

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })
  const { loading, setLoading } = useLoadingState(true)

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'members',
      nestedProps: [],
    })

  const { getMany, remove } = memberApi()

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )

    setSelectedRowData((previousValue) =>
      checked
        ? [
            ...previousValue,
            members.items.find((member) => member.user_id === id),
          ]
        : previousValue.filter((row) => row.user_id !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? members.items.map((member) => member.user_id) : [],
    )

    setSelectedRowData(() => (checked ? members.items : []))
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchMembers()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const fetchMembers = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const members = await getMany(currentPage + 1, rowsPerPage, search)
      members.items.sort((a, b) => {
        return (
          new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        )
      })
      setMembersBackup([...members.items])
      setMembers(members)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOpen = (): void => {
    setOpen(true)
  }
  const handleClose = (): void => {
    setOpen(false)
    setCurrentMember(undefined)
  }

  const handleEdit = (member: IMember): void => {
    setCurrentMember(member)
    setOpen(true)
  }

  useEffect(() => {
    getDefaultFilters()
    fetchMembers()
  }, [])

  useEffect(() => {
    fetchMembers()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    if (action === 'edit') {
      setOpenMultiEditForm(true)
    }
  }, [action])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(members.items, membersBackup),
    [members, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button disabled={loading} variant="contained" onClick={handleOpen}>
          Add Team Member
        </Button>
      ),
    },
  ]

  return (
    <>
      <Box>
        <Toolbar controls={controls.filter((control) => control.display)}>
          <ToolbarControls
            controls={controls.filter((control) => control.display)}
          />
          <ToolbarSearchbar
            handleSearch={handleSearch}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
          />
        </Toolbar>
        {selectedRows.length > 0 && (
          <MultiSelectToolbar
            itemsSelected={selectedRows.length}
            handleAction={handleDeleteMany}
            setAction={setAction}
            actionsAllowed={['delete', 'edit', 'export']}
            itemsSelectedData={selectedRowData}
            model="member"
          />
        )}

        {!loading && members.items.length > 0 && (
          <>
            <Table>
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headers={filteredColumns.filter((c) => c.active)}
                handleSelectAll={handleSelectAll}
              />
              <TableBody>
                {visibleRows.map((member) => (
                  <MemberRow
                    key={member.user_id}
                    member={member}
                    handleEdit={() => handleEdit(member)}
                    filteredColumns={filteredColumns}
                    success={() => fetchMembers()}
                    displayMessage={displayMessage}
                    handleSelectRow={handleSelectRow}
                    selected={selectedRows.includes(member.user_id)}
                  />
                ))}
              </TableBody>
            </Table>

            <TablePagination
              totalItems={members?.total}
              currentPage={currentPage}
              itemsPerPage={rowsPerPage}
              setCurrentPage={setCurrentPage}
              setItemsPerPage={setRowsPerPage}
            />
          </>
        )}

        <LoadingTableData isLoading={loading} />

        <NoData condition={!loading && members.items.length === 0} />

        {open && (
          <SideBar open={open} onClose={handleClose}>
            <MemberForm
              success={fetchMembers}
              member={currentMember}
              onClose={handleClose}
              displayMessage={displayMessage}
            />
          </SideBar>
        )}

        <SideBar
          open={openMultiEditForm}
          onClose={() => {
            setAction('')
            setOpenMultiEditForm(false)
          }}
        >
          <MultiEditForm
            members={selectedRows}
            success={() => fetchMembers()}
            onClose={() => {
              setAction('')
              setOpenMultiEditForm(false)
              setSelectedRows([])
            }}
            displayMessage={displayMessage}
          />
        </SideBar>

        <NotificationDialog
          open={dialog.isOpen}
          onClose={closeDialog}
          message={dialog.message}
          type={dialog.type}
        />
      </Box>
    </>
  )
}

export default TeamMembers
