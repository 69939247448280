import {
  Autocomplete,
  Box,
  Checkbox,
  TextField as MUITextField,
} from '@mui/material'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { Form, TextField, Image, Tabs, IDialogType } from 'components'
import { IProduct } from 'models/Product'
import { productApi } from 'resources/product/api'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import ProductTracking from './ProductTracking'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { IProductGroup } from 'models/ProductGroup'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import useLoadingState from 'hooks/useLoadingState'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { DropdownOption } from 'types'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import { getUserRole } from 'utils/helpers'

interface IProductFormProps {
  product: IProduct | undefined
  onClose: () => void
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
  groups: IProductGroup[]
}

const ProductForm = ({
  product,
  onClose,
  displayMessage,
  success,
  groups,
}: IProductFormProps) => {
  const [currentTab, setCurrentTab] = useState<string>('form')
  const [image, setImage] = useState<File | null>(null)
  const [previewImage, setImagePreview] = useState('')

  const [groupsOptions, setGroupsOptions] = useState<DropdownOption[]>([])

  const [selectedGroup, setSelectedGroup] = useState<DropdownOption | null>(
    () => {
      if (product && product.id_product_group) {
        const group = groups.find(
          (group) => group.id === product.id_product_group,
        )
        if (group) {
          return {
            label: group.name,
            value: group.id,
          }
        }
        return null
      } else {
        return null
      }
    },
  )
  const { create, update } = productApi()

  const { loading, setLoading } = useLoadingState()
  const { loading: groupsLoading, setLoading: setGroupsLoading } =
    useLoadingState()

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const methods = useForm<IProduct>({
    defaultValues: {
      ...product,
    },
  })

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = event?.target?.files?.[0]
    const isImage = file && /^image\//.test(file.type)

    if (!isImage) {
      displayMessage?.('Only images are allowed!', 'info')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) =>
      e.target && e.target.result && setImagePreview(e.target.result as string)

    setImage(file ?? null)
  }

  const handleGroupSelection = (event: React.SyntheticEvent, value: any) => {
    setSelectedGroup(value)
  }

  const buildGroupsOptions = () => {
    if (groups.length > 0) {
      const autoCompleteOptions = groups.map((group) => ({
        label: group.name,
        value: group.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setGroupsOptions(sortedOptions)
    }

    setGroupsLoading(false)
  }

  const onSubmit: SubmitHandler<IProduct> = async (newProduct: IProduct) => {
    try {
      setLoading(true)
      const payload = new FormData()
      Object.keys(newProduct).forEach((key) => {
        if (
          ![
            'devices',
            'image',
            'product_tracking',
            'id_product_group',
          ].includes(key)
        ) {
          if (newProduct[key]) payload.append(key, newProduct[key])
        }
      })
      if (image) {
        payload.append('image', image)
      }
      if (selectedGroup) {
        payload.append('id_product_group', selectedGroup.value)
      }

      if (product) {
        await update(newProduct.id, payload)
        onClose()
        displayMessage?.('Product updated successfully!', 'success')
        success()
      } else {
        await create(payload)
        onClose()
        displayMessage?.('Product created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setGroupsLoading(true)
    buildGroupsOptions()
  }, [groups])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const ProductForm = (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title={product ? 'Edit Product' : 'Add Product'}>
            {product && product.image && previewImage === '' && (
              <Image
                src={product?.image}
                alt={product?.name}
                style={{ outline: 'none' }}
              />
            )}
            {previewImage && (
              <Image
                src={previewImage}
                alt="preview"
                style={{ outline: 'none' }}
              />
            )}

            <ImageUploader
              buttonText={product?.image ? 'Update Image' : 'Upload Image *'}
              helpText="Upload an image (400x250) in high resolution; it may be cropped to a square."
              onChange={handleImageUpload}
            />

            <TextField
              name="name"
              label="Product Name *"
              placeholder="Product Name"
              rules={{
                required: 'Product name is required',
              }}
            />

            <TextField
              name="serial_number"
              label="Serial Number"
              placeholder="Serial Number"
            />

            <TextField name="sku" label="ID/SKU" placeholder="ID/SKU" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <TextField
                name="price"
                label="Cost"
                placeholder="$$.$$"
                rules={
                  methods.watch('price')
                    ? {
                        validate: (value) =>
                          value > 0 || 'Cost must be greater than 0',
                      }
                    : {}
                }
                isNumeric={true}
              />
              <TextField
                name="sales_price"
                label="Sale Price"
                placeholder="$$.$$"
                rules={
                  methods.watch('sales_price')
                    ? {
                        validate: (value) =>
                          value > 0 || 'Sale price must be greater than 0',
                      }
                    : {}
                }
                isNumeric={true}
              />
              <TextField
                name="msrp"
                label="MSRP"
                placeholder="MSRP"
                rules={
                  methods.watch('msrp')
                    ? {
                        validate: (value) =>
                          value > 0 || 'MSRP must be greater than 0',
                      }
                    : {}
                }
                isNumeric={true}
              />
            </Box>
            <TextField
              name="description"
              label="Description"
              placeholder="Description"
            />

            <Autocomplete
              value={selectedGroup}
              options={groupsOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Select a group..." />
              )}
              onChange={handleGroupSelection}
              loading={groupsLoading}
            />

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )

  if (groupsLoading) {
    return <LoadingFormData />
  }

  return (
    <>
      {product && (
        <Tabs
          tabs={[
            {
              label: 'Edit Product',
              value: 'form',
              children: ProductForm,
            },
            {
              label: 'Product History Log',
              value: 'history',
              children: <ProductTracking product={product} />,
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
      {getUserRole() !== 'admin' && !product && ProductForm}
      {getUserRole() === 'admin' && !product && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'form',
              children: ProductForm,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="products"
                  successForm={() => success?.()}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}
export default ProductForm
