import { useEffect, useMemo, useState } from 'react'
import { SelectField, TextField } from 'components'
import { useFormContext } from 'react-hook-form'
import { DeviceType } from 'models'
import useHardwareTypes from 'hooks/useHardwareTypes'
import { DropdownOption } from 'types'

interface IHardwareTypeFieldsProps {
  allowEdit: boolean
  isOjmarOrg: boolean
  isGantnerOrg: boolean
  loadDefaultHardware?: boolean // Only when creating devices
}

const HardwareTypeFields = ({
  allowEdit,
  isOjmarOrg,
  isGantnerOrg,
  loadDefaultHardware = false,
}: IHardwareTypeFieldsProps) => {
  const { watch, setValue } = useFormContext()
  const { orgHardware } = useHardwareTypes()
  const orgSettings = JSON.parse(localStorage.getItem('settings') || '{}')

  const [hardwareOptions, setHardwareOptions] = useState<DropdownOption[]>([])

  const hardwareType = watch('hardware_type')

  useEffect(() => {
    if (orgSettings.default_device_hardware && loadDefaultHardware) {
      setValue('hardware_type', orgSettings.default_device_hardware)
    }
  }, [])

  useEffect(() => {
    if (isOjmarOrg) {
      const ojmar = orgHardware.find((type) => type.value === 'ojmar')
      setHardwareOptions(ojmar ? [ojmar] : [])
    } else if (isGantnerOrg) {
      const gantner = orgHardware.find((type) => type.value === 'gantner')
      setHardwareOptions(gantner ? [gantner] : [])
    } else {
      setHardwareOptions([
        ...orgHardware,
        // Show Virtual Locker always
        {
          label: 'Virtual Locker',
          value: 'virtual',
        },
      ])
    }
  }, [orgHardware])

  return (
    <>
      <SelectField
        name="hardware_type"
        label="Hardware Type"
        items={hardwareOptions}
        rules={{ required: 'Hardware type required' }}
        disabled={!allowEdit}
        defaultValue={
          loadDefaultHardware && orgSettings.default_device_hardware
        }
      />
      {hardwareType === DeviceType.linka && (
        <TextField
          name="mac_address"
          label="Mac Address"
          placeholder="AA:BB:CC:DD:EE:FF"
          rules={{
            pattern: {
              value: /^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$/,
              message: ' MAC address format is incorrect (AA:BB:CC:DD:EE:FF)',
            },
            required: 'MAC address required',
          }}
          disabled={!allowEdit}
        />
      )}
      {hardwareType === DeviceType.spintly && (
        <TextField
          name="integration_id"
          label="Integration Id"
          placeholder="0000"
          onlyInteger={true}
          rules={{ required: 'Integration id required' }}
          disabled={!allowEdit}
        />
      )}
      {hardwareType === DeviceType.ojmar && (
        <>
          <TextField
            name="locker_udn"
            label="Locker Udn"
            placeholder="Locker Udn"
            rules={{ required: 'Locker Udn is required' }}
            disabled={!allowEdit}
          />
          <TextField
            name="user_code"
            label="User Code"
            placeholder="####"
            onlyInteger={true}
            rules={{
              pattern: {
                value: /^[0-9]{4}$/i,
                message:
                  'Invalid user code format. Please enter only 4 digits.',
              },
              required: 'User Code required',
              validate: (value) =>
                value <= 9999 || 'User Code must be equal or lower than 9999',
            }}
            disabled={!allowEdit}
          />
          <TextField
            name="master_code"
            label="Master Code"
            onlyInteger={true}
            placeholder="######"
            rules={{
              pattern: {
                value: /^[0-9]{6}$/i,
                message:
                  'Invalid master code format. Please enter only 6 digits.',
              },
              required: 'Master Code required',
              validate: (value) =>
                value <= 999999 ||
                'Master Code must be equal or lower than 999999',
            }}
            disabled={!allowEdit}
          />
        </>
      )}
      {hardwareType === DeviceType.gantner && (
        <TextField
          name="gantner_id"
          label="Gantner Id"
          placeholder="Gantner Id"
          rules={{ required: 'Gantner Id required' }}
          disabled={!allowEdit}
        />
      )}
      {hardwareType === DeviceType.harbor && (
        <>
          <TextField
            name="harbor_tower_id"
            label="Tower Id"
            placeholder="Tower Id"
            required={true}
            disabled={!allowEdit}
          />
          <TextField
            name="harbor_locker_id"
            label="Locker Id"
            placeholder="Locker Id"
            required={true}
            disabled={!allowEdit}
          />
        </>
      )}
      {hardwareType === DeviceType.dclock && (
        <>
          <TextField
            name="dclock_terminal_no"
            label="Terminal Number"
            placeholder="DC000000000000"
            required={true}
            disabled={!allowEdit}
          />
          <TextField
            name="dclock_box_no"
            label="Box Number"
            placeholder="1"
            required={true}
            disabled={!allowEdit}
          />
        </>
      )}

      {hardwareType === DeviceType.kerong && (
        <>
          <TextField
            name="mac_address"
            label="Mac Address"
            placeholder="AA:BB:CC:DD:EE:FF"
            rules={{
              pattern: {
                value: /^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$/,
                message: ' MAC address format is incorrect (AA:BB:CC:DD:EE:FF)',
              },
              required: 'MAC address required',
            }}
            required={true}
            disabled={!allowEdit}
          />
          {hardwareType !== DeviceType.kerong && (
            <TextField
              name="ip"
              label="IP Address"
              placeholder="IP Address"
              required={false}
              disabled={!allowEdit}
            />
          )}
          <TextField
            name="circuit_unit"
            label="Circuit Unit"
            placeholder="Circuit Unit"
            required={true}
            disabled={!allowEdit}
          />
          {hardwareType !== DeviceType.kerong && (
            <TextField
              name="board_unit"
              label="Board Unit"
              placeholder="Board Unit"
              required={true}
              disabled={!allowEdit}
            />
          )}
          <TextField
            name="hook_port"
            label="Hook Port"
            placeholder="Hook Port"
            required={false}
            type="number"
            disabled={!allowEdit}
          />
          {hardwareType !== DeviceType.kerong && (
            <TextField
              name="additional_metadata"
              label="Additional Metadata (JSON Format)"
              placeholder="Additional Metadata"
              required={false}
              disabled={!allowEdit}
            />
          )}
        </>
      )}
    </>
  )
}
export default HardwareTypeFields
