import { DropdownOption } from 'types'

export const TRANSACTION_TYPES = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'service',
    label: 'Service',
  },
  {
    value: 'storage',
    label: 'Storage',
  },
  {
    value: 'delivery',
    label: 'Delivery',
  },
  {
    value: 'rental',
    label: 'Asset',
  },
]

export const DETAIL_PAGE_TITLE = 'Order'

export const DETAIL_PAGE_LIST_ITEMS = {
  STATUS: 'Status',
  LOCATION: 'Location',
  DEVICE: 'Locker',
  START_DATE: 'Started',
  END_DATE: 'Ended',
  USER: 'Customer',
}

export const DAYS = [
  {
    abbreviation: 'SU',
    name: 'Sunday',
    accesor: 'sunday',
  },
  {
    abbreviation: 'MO',
    name: 'Monday',
    accesor: 'monday',
  },
  {
    abbreviation: 'TU',
    name: 'Tuesday',
    accesor: 'tuesday',
  },
  {
    abbreviation: 'WE',
    name: 'Wednesday',
    accesor: 'wednesday',
  },
  {
    abbreviation: 'TH',
    name: 'Thursday',
    accesor: 'thursday',
  },
  {
    abbreviation: 'FR',
    name: 'Friday',
    accesor: 'friday',
  },
  {
    abbreviation: 'SA',
    name: 'Saturday',
    accesor: 'saturday',
  },
]

export const PERIODS = [
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
]

export const RESERVATION_SETTINGS_PERIODS = [
  {
    value: 'minute',
    label: 'Minute',
  },
  {
    value: 'hour',
    label: 'Hour',
  },
  {
    value: 'day',
    label: 'Day',
  },
]

export const PAYMENT_TYPES = [
  {
    value: 'before',
    label: 'Before reserving the locker',
  },
  {
    value: 'end',
    label: 'At the end of the transaction',
  },
  {
    value: 'partly',
    label: 'Partly before reservation',
  },
  {
    value: 'free',
    label: 'Free reservation',
  },
]

export const RESERVATION_TYPES = [
  {
    value: 'reservations_only',
    label: 'Reservations only',
  },
  {
    value: 'walk-ins-only',
    label: 'Walk-ins only',
  },
  {
    value: 'both',
    label: 'Both',
  },
]

export const PENALTY_REASONS: DropdownOption[] = [
  {
    label: 'Missing Items',
    value: 'missing_items',
  },
  {
    label: 'Damaged Items',
    value: 'damaged_items',
  },
  {
    label: 'Misconduct',
    value: 'misconduct',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const ON_GOING_TRANSACTION_COLOR = '#37980A'
export const EXPIRED_TRANSACTION_COLOR = '#7c7e82'

export const EVENT_DEFAULTS: string[] = [
  'select',
  'invoice_id',
  'order_id',
  'action',
  'user_name',
  'user_phone',
  'user_email',
  'device_name',
  'locker_number',
  'location',
  'mode',
  'status',
  'start_date',
  'end_date',
  'duration',
  'refund',
  'amount',
  'end',
  'qr_code',
  'image',
]

export const EVENT_SHORT_DEFAULTS: string[] = [
  'invoice_id',
  'order_id',
  'device_name',
  'locker_number',
  'location',
  'mode',
  'status',
  'start_date',
  'end_date',
  'duration',
  'refund',
  'amount',
]

export const EVENT_STATUS_OPTIONS: DropdownOption[] = [
  {
    label: 'In Progress',
    value: 'in_progress',
  },

  {
    label: 'Finished',
    value: 'finished',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },

  {
    label: 'Expired',
    value: 'expired',
  },
]
