import { useApiRequest } from 'utils'
import { HTTP_CODES } from 'constants/HTTP'
import { ISettings, IWhiteLabel } from 'models/Settings'
import { getErrorMessage } from 'utils/helpers'

const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'Not found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal error occurred',
}

const settingsApi = () => {
  const { fetchPartnerEndpoint, fetchEndpoint } = useApiRequest()

  const getSettings = async (): Promise<ISettings> => {
    const endpoint = 'settings'
    const method = 'GET'
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method, headers })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const createSettings = async (settings: any): Promise<any> => {
    const endpoint = 'settings'
    const method = 'POST'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(settings)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateSettings = async (settings: any): Promise<any> => {
    const endpoint = 'settings'
    const method = 'PUT'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(settings)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getFilters = async (page: string) => {
    const endpoint = `filters/${page}`
    const method = 'GET'
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method, headers })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateFilters = async (page: string, newFilters: any) => {
    const endpoint = `filters/${page}`
    const method = 'PUT'
    const body = JSON.stringify(newFilters)
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getWhiteLabel = async (): Promise<any> => {
    const endpoint = 'white-label'
    const method = 'GET'
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method, headers })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateWhiteLabel = async (whiteLabel: FormData): Promise<any> => {
    const endpoint = 'white-label'
    const method = 'PUT'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: whiteLabel,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateOrgWhiteLabel = async (
    orgId: string,
    whiteLabel: FormData,
  ): Promise<any> => {
    const endpoint = `white-label/${orgId}`
    const method = 'PUT'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: whiteLabel,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const confirmEmail = async (email: any): Promise<any> => {
    const endpoint = `notification-contact/verify?email=${email}`
    const method = 'POST'
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await fetchEndpoint({
        endpoint,
        method,
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patchWhiteLabel = async (
    payload: Partial<IWhiteLabel>,
  ): Promise<any> => {
    const endpoint = `white-label`
    const method = 'PATCH'
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(payload),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getSettings,
    createSettings,
    updateSettings,
    getFilters,
    updateFilters,
    getWhiteLabel,
    updateWhiteLabel,
    updateOrgWhiteLabel,
    patchWhiteLabel,
    confirmEmail,
  }
}

export default settingsApi
