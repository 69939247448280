import {
  Autocomplete,
  Box,
  Checkbox,
  TextField as MUITextField,
  Typography,
} from '@mui/material'
import { Image, TextField } from 'components'
import { IDevice, DeviceMode } from 'models'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { IProduct } from 'models/Product'

interface IRentalModeFieldsProps {
  device: IDevice | undefined
  allowEdit: boolean
  products: IProduct[]
  productsOptions: {
    label: string
    value: string
    serial_number: string
    description: string
  }[]
  selectedProduct: {
    label: string
    value: string
    serial_number: string
    description: string
  } | null
  loading?: boolean
  handleProductSelection: (event: React.SyntheticEvent, value: any) => void
  handleProductsListClick: (event: React.SyntheticEvent) => void
  openAddProductDialog?: () => void
}

const RentalModeFields = ({
  device,
  allowEdit,
  products,
  productsOptions,
  selectedProduct,
  loading,
  handleProductSelection,
  handleProductsListClick,
  openAddProductDialog,
}: IRentalModeFieldsProps) => {
  const [productData, setProductData] = useState<IProduct | null>(
    device && device.id_product ? device.product : null,
  )

  const { watch, setValue } = useFormContext()
  const mode = watch('mode')

  const handleLoadProductDescriptions = () => {
    const product = products.find(
      (product) => product.id === selectedProduct?.value,
    )

    // Visual change for item_description field:
    setValue('item_description', selectedProduct?.description)

    if (product) {
      // Product data update:
      setProductData(product)
      setValue(
        'item_description',
        selectedProduct?.description || product.description,
      )
    }
  }

  useEffect(() => handleLoadProductDescriptions(), [selectedProduct])

  return mode === DeviceMode.rental || mode === DeviceMode.vending ? (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '0 15px',
          alignItems: 'center',
        }}
      >
        {productData && (
          <Image
            src={productData?.image || ''}
            alt=""
            style={{ borderRadius: '5px' }}
          />
        )}

        <Box
          onClick={handleProductsListClick}
          sx={{
            width: '100%',
          }}
        >
          <Autocomplete
            value={selectedProduct}
            options={productsOptions}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{option.label}</Typography>
                  <Typography color="text.secondary">
                    {option.serial_number}
                  </Typography>
                </Box>
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <MUITextField {...params} label="Select a Product..." />
            )}
            onChange={handleProductSelection}
            loading={loading}
            loadingText={'Loading products...'}
            onOpen={() => {
              if (!loading && productsOptions.length === 0 && allowEdit) {
                openAddProductDialog?.()
              }
            }}
          />
        </Box>
      </Box>
      <TextField
        name="item_description"
        label="Item Description"
        multiline
        disabled={!allowEdit || !!productData}
      />
    </>
  ) : null
}

export default RentalModeFields
