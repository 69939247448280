import { Box, Checkbox, Typography } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { Form, TextField, NumberField, Image, IDialogType } from 'components'
import { IDevice, ILocation } from 'models'
import { ILocker, ILockerWall } from 'models/LockerWall'
import { getRandomInt } from 'utils/helpers'
import { lockerWallApi } from 'resources/locker-wall'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { ChangeEvent, useState } from 'react'
import useLoadingState from 'hooks/useLoadingState'
import ImageUploader from 'components/ImageUploader/ImageUploader'

interface ILockerWallFormProps {
  location: ILocation
  lockerWall: ILockerWall | undefined
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
  mode: 'add' | 'edit'
  setLockerWall: React.Dispatch<React.SetStateAction<ILockerWall | undefined>>
  devicesForLocation: IDevice[]
}

const LockerWallForm = ({
  location,
  lockerWall,
  onClose,
  displayMessage,
  success,
  mode,
  setLockerWall,
  devicesForLocation,
}: ILockerWallFormProps) => {
  const [image, setImage] = useState<File | null>(null)
  const [previewImage, setImagePreview] = useState('')

  const { loading, setLoading } = useLoadingState()
  const { create, update } = lockerWallApi()

  const methods = useForm<ILockerWall>({
    defaultValues: mode === 'edit' ? { ...lockerWall } : {},
  })

  const handleImageClick = (): void => {
    document.getElementById('image')?.click()
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = event?.target?.files?.[0]
    const isImage = file && /^image\//.test(file.type)

    if (!isImage) {
      displayMessage?.('Only images are allowed!', 'info')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) =>
      e.target && e.target.result && setImagePreview(e.target.result as string)

    setImage(file ?? null)
  }

  const buildMatrix = (data: ILockerWall) => {
    const rows = data.qty_tall
    const columns = data.qty_wide

    const kioskRow = getRandomInt(0, rows - 1)
    const kioskColumn = getRandomInt(0, columns - 1)

    let lockers: ILocker[] = []

    if (
      lockerWall &&
      lockerWall.qty_wide === columns &&
      lockerWall.qty_tall === rows
    ) {
      lockers = [...lockerWall.lockers]
      const kioskLocker = lockers.find((locker) => locker.kiosk)
      if (kioskLocker) {
        const kioskPosition = lockers.findIndex((locker) => locker.kiosk)
        lockers[kioskPosition] = {
          ...kioskLocker,
          kiosk: data.is_kiosk ? true : false,
        }
      } else if (data.is_kiosk && !kioskLocker) {
        const newKioskPosition = lockers.findIndex(
          (locker) => locker.y === kioskRow && locker.x === kioskColumn,
        )
        const newKiosk = lockers.find(
          (locker) => locker.y === kioskRow && locker.x === kioskColumn,
        )
        if (newKiosk) {
          lockers[newKioskPosition] = { ...newKiosk, kiosk: true }
        }
      }
    } else {
      for (let i = 0; i < rows; i++) {
        for (var j = 0; j < columns; j++) {
          const isKiosk = i === kioskRow && j === kioskColumn && data.is_kiosk
          const locker: ILocker = { y: i, x: j, kiosk: isKiosk }
          lockers.push(locker)
        }
      }
    }

    const newLockerWall: Partial<ILockerWall> = {
      ...(lockerWall && { id: lockerWall.id }),
      qty_tall: data.qty_tall,
      qty_wide: data.qty_wide,
      name: data.name,
      description: data.description,
      is_kiosk: data.is_kiosk,
      lockers,
      id_location: location.id,
      custom_id: data.custom_id,
    }

    return newLockerWall
  }

  const handleSubmit = async (data: ILockerWall) => {
    try {
      setLoading(true)
      const wall = buildMatrix(data)
      if (mode === 'edit' && lockerWall) {
        const updatedWall = await update(
          lockerWall.id,
          wall,
          image || lockerWall.image,
        )

        const assignedIds = updatedWall.lockers
          .filter((locker) => locker.id)
          .map((locker) => locker.id)

        const devices = devicesForLocation.filter((device) =>
          assignedIds.includes(device.id),
        )
        success()
        setLockerWall({
          ...updatedWall,
          devices,
        })
        onClose()
        displayMessage('Locker Wall updated successfully.', 'success')
        // If we want to update the locker wall in the future the logic will be here
      } else {
        const newWall = await create(wall, image)
        success()
        setLockerWall({
          ...newWall,
          devices: [],
        })
        onClose()
        displayMessage('Locker Wall created successfully.', 'success')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper
            title={mode === 'edit' ? 'Edit Locker Wall' : `Add Locker Wall`}
          >
            {lockerWall && previewImage === '' && mode === 'edit' && (
              <Image src={lockerWall?.image} alt={lockerWall?.name} />
            )}
            {previewImage && (
              <>
                <Typography>
                  Preview <b>{image ? image.name : ''}</b>
                </Typography>
                <Image
                  src={previewImage}
                  alt="Location Image"
                  style={{ outline: 'none' }}
                />
              </>
            )}
            <ImageUploader
              buttonText={location?.image ? 'Update Image' : 'Upload Image *'}
              helpText="Upload an image (400x250) in high resolution; it may be cropped to a square."
              onChange={handleImageUpload}
            />

            <TextField
              name="name"
              label="Name"
              placeholder="Name"
              rules={{
                required: 'Name required',
              }}
            />
            <TextField
              multiline
              name="description"
              label="Description"
              placeholder="Description"
              rules={{
                maxLength: {
                  value: 200,
                  message: 'Description must be 200 characters long maximum.',
                },
              }}
              helperText={`${methods.watch('description')?.length || 0}/${200}`}
            />
            <TextField
              name="custom_id"
              label="Custom ID"
              placeholder="Custom ID"
            />
            <TextField
              name="qty_wide"
              label="# of Doors Wide"
              placeholder="# of Doors Wide"
              rules={{
                required: 'Number of Doors Wide is required',
                validate: (value) =>
                  (value > 0 && value <= 50) ||
                  '# Doors wide must be greater than 0',
              }}
              onlyInteger={true}
            />
            <TextField
              name="qty_tall"
              label="# of Doors Tall"
              placeholder="# of Doors Tall"
              rules={{
                required: 'Number of Doors Tall is required',
                validate: (value) =>
                  (value > 0 && value <= 50) ||
                  '# Doors tall must be greater than 0 and less than or equal to 10',
              }}
              onlyInteger={true}
            />
            <Box>
              <Checkbox
                defaultChecked={lockerWall?.is_kiosk}
                {...methods.register('is_kiosk')}
              />{' '}
              Place a Kiosk
            </Box>
            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default LockerWallForm
