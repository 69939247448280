import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ITransaction, IUser } from 'models'
import { useEffect, useState } from 'react'
import { transactionsApi } from 'resources'
import useLoadingState from 'hooks/useLoadingState'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import UserHistoryItem from './UserHistoryItem'

interface IUserHistory {
  user: IUser
  goBackCallback?: Function | undefined
}

const UserHistory = ({ user, goBackCallback = undefined }: IUserHistory) => {
  const [userTransactions, setUserTransactions] = useState<ITransaction[]>([])

  const { getEventsByUserId } = transactionsApi()
  const { loading, setLoading } = useLoadingState()

  const fetchUserTransactions = async () => {
    try {
      setLoading(true)
      const transactions = await getEventsByUserId(user.id, false)
      setUserTransactions(transactions.slice(0, 100))
    } catch (error) {
      console.error('Error fetching user transactions:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserTransactions()
  }, [])

  return (
    <>
      {loading && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && userTransactions.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography color="text.secondary">
            No transactions for this user.
          </Typography>
        </Box>
      )}

      {!loading && userTransactions.length > 0 && (
        <Box
          sx={{
            marginTop: '0.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          {goBackCallback && (
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => goBackCallback()}
            >
              ◀ Go Back
            </Typography>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <Typography variant="h3">User Transaction History</Typography>
            <Typography variant="h3">{user.name}</Typography>
          </Box>
          <Accordion defaultExpanded sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Transactions...</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Typography fontStyle="italic" color="text.secondary">
                Showing last{' '}
                {userTransactions.length < 100
                  ? userTransactions.length
                  : '100'}{' '}
                user transactions...
              </Typography>
              <Timeline sx={{ padding: 0 }}>
                {userTransactions.map((transaction, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {index !== userTransactions.length - 1 && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <UserHistoryItem transaction={transaction} />
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  )
}

export default UserHistory
