/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import { IDevice, IPromo, ISubscription, IUser } from 'models'

export enum TransactionType {
  service = 'service',
  storage = 'storage',
  rental = 'rental',
  delivery = 'delivery',
}

export enum TransactionStatusDTO {
  in_progress = 'in_progress',
  awaiting_service_pickup = 'awaiting_service_pickup',
  awaiting_service_dropoff = 'awaiting_service_dropoff',
  awaiting_user_pickup = 'awaiting_user_pickup',
  finished = 'finished',
  canceled = 'canceled',
}

export enum TransactionStatus {
  in_progress = 'In progress',
  awaiting_service_pickup = 'Service pickup',
  awaiting_service_dropoff = 'Service dropoff',
  awaiting_user_pickup = 'User pickup',
  awaiting_payment_confirmation = 'Payment confirmation',
  finished = 'Finished',
  canceled = 'Canceled',
  refunded = 'Refunded',
  expired = 'Expired',
  removed = 'Removed',
}

export type PenaltyReason =
  | 'missing_items'
  | 'damaged_items'
  | 'misconduct'
  | 'other'

export interface ITransaction {
  id: string
  invoice_id: string
  order_id: string
  started_at: string
  created_at: string
  ended_at: string
  event_status: TransactionStatus
  event_type: TransactionType
  total: number | string
  id_user: string
  id_device: string
  device: IDevice
  user: IUser
  total_time: string
  signature_url: string
  device_name: string
  location_name: string
  image_url: string
  courier_pin_code: string
  refunded_amount: string
  penalize_charge: string
  penalize_reason: PenaltyReason
  canceled_at: string
  canceled_by: string
  promo: IPromo
  membership: ISubscription
  notification_status: string
  notification_status_date: string
}

export interface ITransactionList {
  items: ITransaction[]
  total: number
  pages: number
}

export interface PublicTransactionDTO {
  app_logo: string
  created_at: string
  device_name: string
  event_status: TransactionStatusDTO
  event_type: TransactionType
  id: string
  invoice_id: string
  location_name: string
  user_email: string
  user_name: string
  user_phone: string
}

export interface ITransactionLog {
  id: string
  log_type: LogType
  log_owner: string
  event: ITransaction
  created_at: Date | string
}

export type LogType = 'lock' | 'unlock' | 'maintenance' | 'report_issue'

export interface CodeDeliveryResponse {
  message: string
  email_success: boolean | null
  sms_success: boolean | null
}
