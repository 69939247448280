import {
  Autocomplete,
  Box,
  Checkbox,
  Typography,
  TextField as MUITextField,
  Chip,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
} from '@mui/material'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { Form, IDialogType, TextField } from 'components'
import { IProduct } from 'models/Product'
import { useEffect, useMemo, useState } from 'react'
import { IProductGroup } from 'models/ProductGroup'
import productGroupApi from 'resources/product-group/api/productGroupApi'
import { productApi } from 'resources/product'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { RETURN_TO_OPTIONS } from 'pages/Products/constants'
import { ISize } from 'models'
import { sizeApi } from 'resources'
import useLoadingState from 'hooks/useLoadingState'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { Image } from 'components'
interface IProductGroupFormProps {
  group: IProductGroup | undefined
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
}

const MAX_NAME_LENGTH = 50
const MAX_TRANSACTION_NUMBER_LENGTH = 10

const ProductGroupForm = ({
  group,
  onClose,
  displayMessage,
  success,
}: IProductGroupFormProps) => {
  const [products, setProducts] = useState<IProduct[]>([])
  const [options, setOptions] = useState<
    {
      label: string
      value: string
      serial_number: string
      description: string
    }[]
  >([])
  const [productsToAdd, setProductsToAdd] = useState<string[]>([])
  const [productsToRemove, setProductsToRemove] = useState<string[]>([])
  const [previewImage, setImagePreview] = useState('')
  const [activateCharging, setActivateCharging] = useState<boolean>(
    group ? group.charging_time > 0 : false,
  )
  const [returnTo, setReturnTo] = useState<string>(() => {
    if (group && group.one_to_one) {
      return 'one_to_one'
    } else if (group && !group.one_to_one && group.size) {
      return 'any_locker_of_same_size_and_location'
    } else if (!group) {
      return ''
    } else return ''
  })
  const [sizes, setSizes] = useState<ISize[]>([])
  const [selectedSize, setSelectedSize] = useState<string>(
    group?.size?.id || '',
  )
  const [image, setImage] = useState<File | null>(null)

  const theme = useTheme()

  const { create, update } = productGroupApi()
  const { getMany } = productApi()
  const { getMany: getSizes } = sizeApi()

  const { loading, setLoading } = useLoadingState()
  const { loading: productsLoading, setLoading: setProductsLoading } =
    useLoadingState()
  const { loading: sizesLoading, setLoading: setSizesLoading } =
    useLoadingState()

  const methods = useForm<IProductGroup>({
    defaultValues: { ...group },
  })

  const sizeOptions = useMemo(
    () =>
      sizes.map((size) => ({
        value: size.id,
        label: `${size.name} (${size.width}" x ${size.depth}" x ${size.height}")`,
      })),
    [sizes],
  )

  const fetchProducts = async (): Promise<void> => {
    try {
      setProductsLoading(true)
      const products = await getMany(1, 10000)
      setProducts(products.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setProductsLoading(false)
    }
  }

  const fetchSizes = async (): Promise<void> => {
    try {
      setSizesLoading(true)
      const sizes = await getSizes(1, 10000)
      setSizes(sizes.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setSizesLoading(false)
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const productsSelected: string[] = value.map((option) => option.value)
    setProductsToAdd(productsSelected)
  }

  const handleSelect = (userId: string) => {
    if (productsToRemove.includes(userId)) {
      setProductsToRemove(productsToRemove.filter((id) => id !== userId))
    } else {
      setProductsToRemove((prevState) => [...prevState, userId])
    }
  }

  const buildOptions = () => {
    if (products.length > 0) {
      const notAssignedProducts = products.filter(
        (product) => !group?.products.some((p) => p.id === product.id),
      )
      const autoCompleteOptions = notAssignedProducts.map((product) => ({
        label: product.name,
        value: product.id,
        serial_number: product.serial_number,
        description: product.description,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) =>
        a.label.localeCompare(b.label),
      )
      setOptions(sortedOptions)
    }
  }

  const onSubmit: SubmitHandler<IProductGroup> = async (
    newGroup: IProductGroup,
  ) => {
    try {
      setLoading(true)
      const formData = new FormData()

      if (image) {
        formData.append('image', image)
      }

      if (group) {
        const currentProducts = group.products.map((product) => product.id)
        const newProducts = [
          ...currentProducts.filter((id) => !productsToRemove.includes(id)),
          ...productsToAdd,
        ]

        await update(newGroup.id, {
          ...newGroup,
          total_inventory: newProducts.length,
          one_to_one: returnTo === 'one_to_one',
          id_size: selectedSize ? selectedSize : null,
          charging_time:
            newGroup.charging_time > 0 && activateCharging
              ? newGroup.charging_time
              : 0,
          products: newProducts,
          image: formData.has('image') ? formData : undefined,
        })
        onClose()
        displayMessage('Product group updated successfully!', 'success')
        success()
      } else {
        await create({
          ...newGroup,
          total_inventory: productsToAdd.length,
          one_to_one: returnTo === 'one_to_one',
          id_size: selectedSize ? selectedSize : null,
          charging_time:
            newGroup.charging_time > 0 && activateCharging
              ? newGroup.charging_time
              : 0,
          products: productsToAdd,
          image: formData.has('image') ? formData : undefined,
        })
        onClose()
        displayMessage('Product group created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProducts()
    fetchSizes()
  }, [])

  useEffect(() => {
    buildOptions()
  }, [products])

  useEffect(() => {
    if (returnTo === 'one_to_one') {
      setSelectedSize('')
    }
  }, [returnTo])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const autoRepair = methods.watch('auto_repair')

  if (
    sizesLoading ||
    (productsLoading && (options.length === 0 || products.length === 0))
  ) {
    return <LoadingFormData />
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper
            title={group ? 'Edit Product Group' : 'Add Product Group'}
          >
            {previewImage && (
              <>
                <Image
                  src={previewImage}
                  alt="Group Image"
                  style={{ outline: 'none' }}
                />
              </>
            )}

            {group?.image && previewImage === '' && (
              <>
                <Typography variant="h5">Group Image:</Typography>
                <Image
                  src={group.image}
                  alt="Group Image"
                  style={{ outline: 'none' }}
                />
              </>
            )}
            <ImageUploader
              buttonText="Upload Image"
              helpText="Upload a rectangular image preferably of size 400x250 in high resolution for better display results."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const file = e.target.files?.[0]
                if (file) {
                  setImage(file)
                  const reader = new FileReader()
                  reader.readAsDataURL(file)
                  reader.onload = (e) =>
                    e.target &&
                    e.target.result &&
                    setImagePreview(e.target.result as string)
                }
              }}
            />
            <TextField
              name="name"
              label="Product Group Name"
              placeholder="Product Group Name"
              rules={{
                required: 'Product group name is required',
                maxLength: {
                  value: MAX_NAME_LENGTH,
                  message: `Maximum length is ${MAX_NAME_LENGTH} characters`,
                },
              }}
            />
            <Autocomplete
              multiple
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>{option.label}</Typography>
                    <Typography color="text.secondary">
                      {option.serial_number}
                    </Typography>
                  </Box>
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Assign products to group..." />
              )}
              onChange={handleToggle}
              loadingText="Loading..."
              loading={productsLoading && options.length === 0}
            />

            {group && (
              <>
                <Typography variant="h5">Products assigned to group</Typography>
                {group.products.length > 0 && (
                  <Typography variant="caption" color="text.secondary">
                    {group.products.length} products assigned to this group
                  </Typography>
                )}
                <Typography variant="caption" color="text.secondary">
                  {group.products.length > 0
                    ? 'Select the products that you want to remove from this group and hit submit'
                    : 'No products assigned to this group'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '0.75rem',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {group.products.length > 0 &&
                    group.products.map((product) => (
                      <Chip
                        key={product.id}
                        label={`${product.name} - Serial Number: ${product.serial_number}`}
                        onClick={() => handleSelect(product.id)}
                        onDelete={() => handleSelect(product.id)}
                        deleteIcon={
                          productsToRemove.includes(product.id) ? (
                            <DoneIcon />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        variant={
                          productsToRemove.includes(product.id)
                            ? 'filled'
                            : 'outlined'
                        }
                        color={
                          productsToRemove.includes(product.id)
                            ? 'primary'
                            : 'default'
                        }
                      />
                    ))}
                </Box>
              </>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '50%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={() =>
                        setActivateCharging((previousValue) => !previousValue)
                      }
                      checked={activateCharging}
                    />
                  }
                  label="Activate charging time"
                />
              </FormControl>
              {activateCharging && (
                <FormControl sx={{ width: '50%' }}>
                  <TextField
                    name="charging_time"
                    label="Minutes"
                    type="number"
                    required
                    rules={{
                      maxLength: {
                        value: 6,
                        message: 'Maximum 6 digits allowed',
                      },
                    }}
                  />
                </FormControl>
              )}
            </Box>

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Product return to...
              </InputLabel>
              <Select
                label="Product return to..."
                required={true}
                value={returnTo}
                onChange={(event) => setReturnTo(event.target.value)}
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundImage: 'none',
                        boxShadow: 3,
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#2A2E34' : '#f7f7f7',
                      },
                    },
                  },
                }}
              >
                {RETURN_TO_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {returnTo && returnTo !== 'one_to_one' && (
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Locker Size
                </InputLabel>
                <Select
                  label="Locker Size"
                  required={true}
                  value={selectedSize}
                  onChange={(event) => setSelectedSize(event.target.value)}
                >
                  {sizeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '50%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('auto_repair')}
                      defaultChecked={group?.auto_repair || false}
                    />
                  }
                  label="Auto Repair"
                />
              </FormControl>

              {autoRepair && (
                <FormControl sx={{ width: '50%' }}>
                  <TextField
                    name="transaction_number"
                    label="# of Transactions"
                    placeholder="Transaction Number"
                    onlyInteger={true}
                    rules={{
                      maxLength: {
                        value: MAX_TRANSACTION_NUMBER_LENGTH,
                        message: `Max length is ${MAX_TRANSACTION_NUMBER_LENGTH} digits`,
                      },
                    }}
                  />
                </FormControl>
              )}
            </Box>

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}
export default ProductGroupForm
