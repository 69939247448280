import { Box, Typography, Checkbox, useMediaQuery, Theme } from '@mui/material'

import {
  Form,
  TextField,
  SelectField,
  IDialogType,
  ToggleField,
  IconButton,
} from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { settingsApi, sizeApi } from 'resources'
import { COUNTRIES_OPTIONS } from 'pages/Settings/constants'
import { ISettings } from 'models/Settings'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { useEffect, useState } from 'react'
import useHardwareTypes from 'hooks/useHardwareTypes'
import { DropdownOption } from 'types'
import useDeviceModes from 'hooks/useDeviceModes'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

interface IDefaultsFormProps {
  orgSettings: Partial<ISettings>
  fetchOrgSettings: () => void
  displayMessage: (message: string, type?: IDialogType) => void
}

const DefaultsForm = ({
  orgSettings,
  fetchOrgSettings,
  displayMessage,
}: IDefaultsFormProps) => {
  const [unlimited, setUnlimited] = useState<boolean>(() => {
    if (orgSettings.default_max_reservations) {
      return false
    }
    return true
  })

  const [secondTermsAndConditions, setSecondTermsAndConditions] =
    useState<boolean>(Boolean(orgSettings.terms_condition_2nd) || false)
  const [expiredParcels, setExpiredParcels] = useState<boolean>(
    Boolean(
      orgSettings.parcel_expiration || orgSettings.parcel_expiration_unit,
    ),
  )

  const [secondContact, setSecondContact] = useState<boolean>(
    orgSettings.new_contact_notification_enabled || false,
  )
  const [verificationEmailSent, setVerificationEmailSent] =
    useState<boolean>(false)
  const [emailVerified, setEmailVerified] = useState<boolean>(
    Boolean(orgSettings.new_contact_notification_email_verified) || false,
  )

  const settings = JSON.parse(localStorage.getItem('settings') || '{}')

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const { createSettings, updateSettings, patchWhiteLabel, confirmEmail } =
    settingsApi()
  const { orgHardware } = useHardwareTypes()

  const methods = useForm<ISettings>({
    defaultValues: {
      ...orgSettings,
    },
  })

  const { loading, setLoading } = useLoadingState()
  const { getMany: getSizes } = sizeApi()
  const { modes } = useDeviceModes()

  const [sizes, setSizes] = useState<DropdownOption[]>([])

  const fetchSizes = async () => {
    try {
      const sizes = await getSizes(1, 10000)
      setSizes(
        sizes.items.map((size) => ({
          value: size.id,
          label: size.name,
        })),
      )
    } catch (error) {
      displayMessage?.((error as Error).message)
    }
  }

  const onSubmit = async (newLocker: ISettings) => {
    try {
      setLoading(true)
      const defaultNotificationEntity = newLocker.default_notification
      localStorage.setItem(
        'defaultNotificationEntity',
        defaultNotificationEntity,
      )
      const reservations = unlimited ? null : newLocker.default_max_reservations
      if (settings) {
        const updatedSettings = {
          ...settings,
          ...newLocker,
          default_max_reservations: reservations,
          new_contact_notification_enabled: secondContact,
          default_notification_email: methods.getValues(
            'default_notification_email',
          ),
          default_notification_phone: methods.getValues(
            'default_notification_phone',
          ),
          default_notification: methods.getValues('default_notification'),
        }
        await updateSettings(updatedSettings)

        if (
          !secondTermsAndConditions &&
          newLocker.terms_condition_2nd &&
          newLocker.terms_name_2nd
        ) {
          await patchWhiteLabel({
            terms_condition_2nd: null,
            terms_name_2nd: null,
          })
        }
        if (
          newLocker.terms_condition &&
          settings.terms_condition !== newLocker.terms_condition
        ) {
          await patchWhiteLabel({
            terms_condition: newLocker.terms_condition,
          })
        }
        if (
          newLocker.terms_condition_2nd &&
          (settings.terms_condition_2nd !== newLocker.terms_condition_2nd ||
            settings.terms_name_2nd !== newLocker.terms_name_2nd)
        ) {
          await patchWhiteLabel({
            terms_condition_2nd: newLocker.terms_condition_2nd,
            terms_name_2nd: newLocker.terms_name_2nd,
          })
        }

        displayMessage('Settings updated successfully', 'success')
        fetchOrgSettings()
      } else {
        await createSettings({
          ...newLocker,
          default_max_reservations: reservations,
          invoice_prefix: 'ZTT',
        })
        displayMessage('Settings created successfully', 'success')

        if (newLocker.terms_condition) {
          await patchWhiteLabel({
            terms_condition: newLocker.terms_condition,
          })
        }
        if (newLocker.terms_condition_2nd || newLocker.terms_name_2nd) {
          await patchWhiteLabel({
            terms_condition_2nd: newLocker.terms_condition_2nd || null,
            terms_name_2nd: newLocker.terms_name_2nd || null,
          })
        }
        fetchOrgSettings()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
      methods.reset()
    } finally {
      setLoading(false)
    }
  }

  const handleSendVerificationEmail = async () => {
    try {
      setVerificationEmailSent(true)
      await updateSettings({
        default_notification_email: methods.getValues(
          'default_notification_email',
        ),
        new_contact_notification_enabled: true,
        default_notification: 'email',
      })
      await confirmEmail(methods.getValues('default_notification_email'))
      displayMessage(
        'Verification email sent. Please check your inbox.',
        'success',
      )
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  useEffect(() => {
    fetchSizes()
  }, [])

  useEffect(() => {
    if (orgHardware.length === 1) {
      methods.setValue('default_device_hardware', orgHardware[0].value)
    }

    if (modes.length === 1) {
      methods.setValue('default_device_mode', modes[0].value)
    }
  }, [orgHardware, modes])

  useEffect(() => {
    const duration = methods.watch('parcel_expiration')
    const unit = methods.watch('parcel_expiration_unit')
    if (!expiredParcels && (unit || duration)) {
      methods.setValue('parcel_expiration', null)
      methods.setValue('parcel_expiration_unit', null)
    }
  }, [expiredParcels])

  const notification_types = [
    { label: 'Email', value: 'email' },
    { label: 'SMS', value: 'sms' },
  ]

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              whiteSpace: 'nowrap',
              width: smDown ? '100%' : 400,
            }}
          >
            <FormWrapper>
              <SelectField
                name="default_country"
                label="Country"
                items={COUNTRIES_OPTIONS}
              />

              <Typography variant="h5">Mode</Typography>

              <SelectField
                name="default_device_mode"
                label="Mode"
                items={modes}
                defaultValue={modes.length === 1 ? modes[0].value : ''}
              />

              <Typography variant="h5">Hardware</Typography>

              <SelectField
                name="default_device_hardware"
                label="Hardware"
                items={orgHardware}
                defaultValue={
                  orgHardware.length === 1 ? orgHardware[0].value : ''
                }
              />

              <Typography variant="h5">Size</Typography>

              <SelectField name="default_id_size" label="Size" items={sizes} />

              <Typography variant="h5">Notifications</Typography>

              <SelectField
                name="default_notification"
                label="Default Notification"
                items={notification_types}
              />
              <Typography color="text.secondary">
                This will be the primary setting for notifications.
              </Typography>

              {methods.watch('default_notification') === 'email' && (
                <>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <TextField
                      name="default_notification_email"
                      label="Email"
                      placeholder="Email"
                      rules={{
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      }}
                    />

                    <Box
                      sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {!emailVerified && !verificationEmailSent && (
                        <IconButton
                          onClick={handleSendVerificationEmail}
                          title="Send verification link"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[800],
                            '&:hover': {
                              backgroundColor: (theme) =>
                                theme.palette.grey[700],
                            },
                            width: 30,
                            height: 30,
                            p: 0,
                          }}
                        >
                          <ArrowForwardIcon
                            sx={{ color: '#fff', fontSize: 18 }}
                          />
                        </IconButton>
                      )}

                      {!emailVerified && verificationEmailSent && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: (theme) =>
                              theme.palette.success.main,
                            borderRadius: '50%',
                            width: 30,
                            height: 30,
                            justifyContent: 'center',
                          }}
                        >
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                              fill="currentColor"
                              fillRule="evenodd"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </Box>
                      )}

                      {emailVerified && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ fontStyle: 'italic', color: 'grey.500' }}
                          >
                            verified
                          </Typography>
                          <CheckCircleIcon
                            sx={{ fontSize: 16, color: 'grey.500' }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {!orgSettings.new_contact_notification_email_verified && (
                    <Typography color="text.secondary">
                      <strong>Note:</strong> This email will be used as sender
                      for new contact
                      <br /> notification but needs to be{' '}
                      <strong>verified.</strong> please check your
                      <br />
                      inbox for verification email.
                    </Typography>
                  )}
                </>
              )}

              {methods.watch('default_notification') === 'sms' && (
                <>
                  <TextField
                    name="default_notification_phone"
                    label="Phone Number"
                    placeholder="Phone Number"
                    rules={{
                      pattern: {
                        value: /^\+[1-9]/i,
                        message: 'Please enter country code',
                      },
                    }}
                  />
                  <Typography color="text.secondary">
                    This will be used to send the notifications.
                  </Typography>
                </>
              )}

              <ToggleField
                name="maintenance_on_issue"
                label="Set device to maintenance on issue report"
              />

              <Typography variant="h5">User Transactions Limit</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={unlimited}
                  onChange={() =>
                    setUnlimited((previousValue) => !previousValue)
                  }
                />
                <Typography variant="h5">Unlimited</Typography>
              </Box>
              {!unlimited && (
                <TextField
                  name="default_max_reservations"
                  label="Max number of reservations*"
                  placeholder="Max number of reservations*"
                  rules={{
                    required: 'A number is required',
                  }}
                  onlyInteger={true}
                />
              )}

              <FormActions
                cancelText="Clear"
                onClose={() => {
                  methods.reset()
                }}
                loading={loading}
                disableSubmit={disableSubmit}
              />
            </FormWrapper>
          </Box>
        </Form>
      </FormProvider>
    </>
  )
}
export default DefaultsForm
