import { THEME_COLOR, NAVBAR_BG, SIDEBAR_BG, DARK_THEME } from '../constants'

export interface InitialState {
  activeNavbarBg: string
  activeSidebarBg: string
  activeMode: string
  activeTheme: string
  SidebarWidth: number
}

const INIT_STATE: InitialState = {
  activeNavbarBg: '#0b70fb', // This can be any color,
  activeSidebarBg: '#ffffff', // This can be any color
  activeMode: 'light', // This can be light or dark
  activeTheme: 'PURPLE_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME
  SidebarWidth: 240,
}

const CustomizerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      }
    case DARK_THEME:
      return {
        ...state,
        activeMode: action.payload,
      }
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      }
    case THEME_COLOR:
      return {
        ...state,
        activeTheme: action.payload,
      }

    default:
      return state
  }
}

export default CustomizerReducer
