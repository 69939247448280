export const ROLES = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'viewer', label: 'Viewer' },
]

export const USER_DEFAULTS: string[] = [
  'select',
  'name',
  'last_name',
  'email',
  'address',
  'phone_number',
  'user_id',
  'pin_code',
  'qr_code',
  'action',
]

export const USER_SHORT_DEFAULTS: string[] = [
  'name',
  'last_name',
  'email',
  'address',
  'phone_number',
  'user_id',
  'pin_code',
]
