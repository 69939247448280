import { useApiRequest } from 'utils'
import { DeviceMode, ILocation, ILocationList } from 'models'
import { getErrorMessage } from 'utils/helpers'

const locationApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
    id?: string,
  ): Promise<any> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      ...(id && { id_location: id ?? '' }),
      search,
    })

    const endpoint = `locations?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (location: FormData) => {
    const endpoint = `locations`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: location,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (locationId: string, location: FormData) => {
    const endpoint = `locations/${locationId}`
    const method = 'PUT'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: location,
      })
      if (!response.ok) {
        const result = await response.json()
        throw new Error((result as Error).message)
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patch = async (locationId: string, location: Partial<ILocation>) => {
    const endpoint = `location/${locationId}`
    const method = 'PATCH'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(location)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]) => {
    const endpoint = `locations`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getDevices = async (locationId: string) => {
    const endpoint = `locations/${locationId}/devices`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const unlockByLocation = async (locationId: string) => {
    const endpoint = `location/${locationId}/unlock`
    const method = 'PATCH'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `locations/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getSizesByLocation = async (
    locationId: string,
    mode?: DeviceMode | null,
    status?: string | null,
  ) => {
    const urlParams = new URLSearchParams({
      ...(mode && { by_mode: mode }),
      ...(status && { by_status: status }),
    })

    const endpoint = `locations/${locationId}/sizes?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    create,
    update,
    patch,
    remove,
    getDevices,
    unlockByLocation,
    uploadCSV,
    getSizesByLocation,
  }
}
export default locationApi
