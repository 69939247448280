import { useEffect, useRef } from 'react'

interface Canvas3DPreviewProps {
  width: number
  height: number
  depth: number
}

const isValidCoordinate = (x: number, y: number) => {
  return Number.isFinite(x) && Number.isFinite(y)
}

const createSafeGradient = (
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
) => {
  if (!isValidCoordinate(x1, y1) || !isValidCoordinate(x2, y2)) {
    console.warn('Invalid gradient coordinates:', { x1, y1, x2, y2 })
    return null
  }

  try {
    return ctx.createLinearGradient(x1, y1, x2, y2)
  } catch (error) {
    console.error('Error creating gradient:', error)
    return null
  }
}

export const Canvas3DPreview = ({
  width = 10,
  height = 10,
  depth = 10,
}: Canvas3DPreviewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // Set center point
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2

    // Better camera angles for more realistic isometric view
    const angleX = Math.PI / 6 // 30 degrees
    const angleY = Math.PI / 4 // 45 degrees

    // Improved scale calculation for better proportions
    const maxDimension = Math.max(width, height, depth)
    const scale = (Math.min(canvas.width, canvas.height) * 0.4) / maxDimension

    // Convert 3D point to 2D
    const project = (x: number, y: number, z: number) => {
      const isoX = (x - z) * Math.cos(angleX)
      const isoY = y + (x + z) * Math.sin(angleY)
      return {
        x: centerX + isoX * scale,
        y: centerY - isoY * scale,
      }
    }

    // Calculate all points
    const points = {
      // Front face
      ftl: project(-width / 2, height, -depth / 2),
      ftr: project(width / 2, height, -depth / 2),
      fbl: project(-width / 2, 0, -depth / 2),
      fbr: project(width / 2, 0, -depth / 2),
      // Back face
      btl: project(-width / 2, height, depth / 2),
      btr: project(width / 2, height, depth / 2),
      bbl: project(-width / 2, 0, depth / 2),
      bbr: project(width / 2, 0, depth / 2),
    }

    // Draw faces with enhanced visual style
    const drawFaces = () => {
      ctx.lineWidth = 2
      ctx.lineJoin = 'round'
      ctx.lineCap = 'round'

      // Back face (draw first as it's furthest)
      const backGradient = createSafeGradient(
        ctx,
        points.btl.x,
        points.btl.y,
        points.bbr.x,
        points.bbr.y,
      )

      if (backGradient) {
        backGradient.addColorStop(0, '#d8d8d8')
        backGradient.addColorStop(1, '#c0c0c0')
        ctx.fillStyle = backGradient
      } else {
        ctx.fillStyle = '#d0d0d0' // Fallback solid color
      }

      ctx.beginPath()
      ctx.moveTo(points.btl.x, points.btl.y)
      ctx.lineTo(points.btr.x, points.btr.y)
      ctx.lineTo(points.bbr.x, points.bbr.y)
      ctx.lineTo(points.bbl.x, points.bbl.y)
      ctx.closePath()
      ctx.fill()
      ctx.strokeStyle = '#999'
      ctx.stroke()

      // Left face
      const leftGradient = createSafeGradient(
        ctx,
        points.ftl.x,
        points.ftl.y,
        points.btl.x,
        points.btl.y,
      )

      if (leftGradient) {
        leftGradient.addColorStop(0, '#e0e0e0')
        leftGradient.addColorStop(1, '#d0d0d0')
        ctx.fillStyle = leftGradient
      } else {
        ctx.fillStyle = '#d0d0d0' // Fallback solid color
      }

      ctx.beginPath()
      ctx.moveTo(points.ftl.x, points.ftl.y)
      ctx.lineTo(points.btl.x, points.btl.y)
      ctx.lineTo(points.bbl.x, points.bbl.y)
      ctx.lineTo(points.fbl.x, points.fbl.y)
      ctx.closePath()
      ctx.fill()
      ctx.strokeStyle = '#999'
      ctx.stroke()

      // Bottom face
      const bottomGradient = createSafeGradient(
        ctx,
        points.fbl.x,
        points.fbl.y,
        points.bbr.x,
        points.bbr.y,
      )

      if (bottomGradient) {
        bottomGradient.addColorStop(0, '#e8e8e8')
        bottomGradient.addColorStop(1, '#d8d8d8')
        ctx.fillStyle = bottomGradient
      } else {
        ctx.fillStyle = '#d0d0d0' // Fallback solid color
      }

      ctx.beginPath()
      ctx.moveTo(points.fbl.x, points.fbl.y)
      ctx.lineTo(points.fbr.x, points.fbr.y)
      ctx.lineTo(points.bbr.x, points.bbr.y)
      ctx.lineTo(points.bbl.x, points.bbl.y)
      ctx.closePath()
      ctx.fill()
      ctx.strokeStyle = '#999'
      ctx.stroke()

      // Right face
      const rightGradient = createSafeGradient(
        ctx,
        points.ftr.x,
        points.ftr.y,
        points.bbr.x,
        points.bbr.y,
      )

      if (rightGradient) {
        rightGradient.addColorStop(0, '#e8e8e8')
        rightGradient.addColorStop(1, '#d0d0d0')
        ctx.fillStyle = rightGradient
      } else {
        ctx.fillStyle = '#d0d0d0' // Fallback solid color
      }

      ctx.beginPath()
      ctx.moveTo(points.ftr.x, points.ftr.y)
      ctx.lineTo(points.btr.x, points.btr.y)
      ctx.lineTo(points.bbr.x, points.bbr.y)
      ctx.lineTo(points.fbr.x, points.fbr.y)
      ctx.closePath()
      ctx.fill()
      ctx.strokeStyle = '#999'
      ctx.stroke()

      // Top face
      const topGradient = createSafeGradient(
        ctx,
        points.ftl.x,
        points.ftl.y,
        points.btr.x,
        points.btr.y,
      )

      if (topGradient) {
        topGradient.addColorStop(0, '#ffffff')
        topGradient.addColorStop(1, '#f0f0f0')
        ctx.fillStyle = topGradient
      } else {
        ctx.fillStyle = '#f0f0f0' // Fallback solid color
      }

      ctx.beginPath()
      ctx.moveTo(points.ftl.x, points.ftl.y)
      ctx.lineTo(points.ftr.x, points.ftr.y)
      ctx.lineTo(points.btr.x, points.btr.y)
      ctx.lineTo(points.btl.x, points.btl.y)
      ctx.closePath()
      ctx.fill()
      ctx.strokeStyle = '#999'
      ctx.stroke()

      // Front face (draw last as it's closest)
      const frontGradient = createSafeGradient(
        ctx,
        points.ftl.x,
        points.ftl.y,
        points.fbr.x,
        points.fbr.y,
      )

      if (frontGradient) {
        frontGradient.addColorStop(0, '#f8f8f8')
        frontGradient.addColorStop(1, '#e8e8e8')
        ctx.fillStyle = frontGradient
      } else {
        ctx.fillStyle = '#e8e8e8' // Fallback solid color
      }

      ctx.beginPath()
      ctx.moveTo(points.ftl.x, points.ftl.y)
      ctx.lineTo(points.ftr.x, points.ftr.y)
      ctx.lineTo(points.fbr.x, points.fbr.y)
      ctx.lineTo(points.fbl.x, points.fbl.y)
      ctx.closePath()
      ctx.fill()
      ctx.strokeStyle = '#999'
      ctx.stroke()
    }

    // Add ambient shadow
    ctx.shadowColor = 'rgba(0, 0, 0, 0.15)'
    ctx.shadowBlur = 15
    ctx.shadowOffsetX = 8
    ctx.shadowOffsetY = 8

    drawFaces()
  }, [width, height, depth])

  return (
    <canvas
      ref={canvasRef}
      width={400}
      height={300}
      style={{
        border: '1px solid #eee',
        borderRadius: '8px',
        marginBottom: '20px',
        backgroundColor: '#fff',
      }}
    />
  )
}
