//import { NOTIFICATION_API_URL } from '../constants' // Notification service endpoint
import { PUBLIC_API_URL } from '../constants' // Bridge endpoint for notification service
import { useAuth } from 'resources'

const useNotificationApi = () => {
  const { getJwtToken } = useAuth()

  const uploadImagesToNotification = async ({
    notificationId,
    header_image,
    body_image,
    footer_image,
  }: {
    notificationId: string
    header_image?: File | null
    body_image?: File | null
    footer_image?: File | null
  }) => {
    try {
      const jwtToken = await getJwtToken()
      const formData = new FormData()
      if (header_image) formData.append('header_image', header_image)
      if (body_image) formData.append('body_image', body_image)
      if (footer_image) formData.append('footer_image', footer_image)

      const response = await fetch(
        //`${NOTIFICATION_API_URL}/notification/notifications/${notificationId}/upload-images`, // Commenting out for now
        `${PUBLIC_API_URL}notifications-service/notifications/${notificationId}/upload-images`, // Using public API URL for now
        {
          method: 'PATCH',
          body: formData,
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            accept: 'application/json',
          },
        },
      )

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`)
      }

      return response.json()
    } catch (error) {
      throw new Error(
        `An error occurred while uploading images. ${(error as Error).message}`,
      )
    }
  }

  return {
    uploadImagesToNotification,
  }
}

export default useNotificationApi
