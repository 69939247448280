import {
  IDevice,
  IProduct,
  ITransaction,
  ISize,
  ILocation,
  IProductGroup,
  ICondition,
  IUser,
  IGroup,
  IMember,
  IReservation,
  ISubscription,
  IIssue,
} from 'models'

export const replaceCommaWithSpace = (str: string): string =>
  str.replace(/,/g, ' ')

export const setHardwareDetails = (obj: IDevice) => {
  const data = {
    linka: {
      mac_address: obj.mac_address,
    },
    spintly: {
      integration_id: obj.integration_id,
    },
    ojmar: {
      locker_udn: obj.locker_udn,
      user_code: obj.user_code,
      master_code: obj.master_code,
    },
    gantner: {
      gantner_id: obj.gantner_id,
    },
    harbor: {
      harbor_tower_id: obj.harbor_tower_id,
      harbor_locker_id: obj.harbor_locker_id,
    },
    dclocker: {
      dclock_terminal_no: obj.dclock_terminal_no,
      dclock_box_no: obj.dclock_box_no,
    },
    kerong: {
      mac_address: obj.mac_address,
      ip: obj.ip,
      circuit_unit: obj.circuit_unit,
      board_unit: obj.board_unit,
      hook_port: obj.hook_port,
    },
  }

  return data[obj.hardware_type]
}
export const DeviceColumnValues = (obj: IDevice) => {
  const data = {
    id: obj.id,
    id_location: obj.id_location,
    id_price: obj.id_price,
    id_size: obj.id_size,
    name: obj.name,
    custom_identifier: obj.custom_identifier,
    item: obj.item,
    item_description: obj.item_description,
    locker_number: obj.locker_number,
    mode: obj.mode,
    price_required: obj.price_required,
    hardware_type: obj.hardware_type,
    status: obj.status,
    lock_status: obj.lock_status,
  }

  return { ...data, ...setHardwareDetails(obj) }
}
export const DeviceHeaders = [
  'id',
  'id_location',
  'id_price',
  'id_size',
  'name',
  'custom_identifier',
  'item',
  'item_description',
  'locker_number',
  'mode',
  'price_required',
  'hardware_type',
  'status',
  'lock_status',
  'mac_address',
  'integration_id',
  'locker_udn',
  'user_code',
  'master_code',
  'gantner_id',
  'harbor_tower_id',
  'harbor_locker_id',
  'dclock_terminal_no',
  'dclock_box_no',
  'ip',
  'circuit_unit',
  'board_unit',
  'hook_port',
]
export const DeviceLabels = {
  id: 'ID',
  id_location: 'Location',
  id_price: 'Price',
  id_size: 'Size',
  name: 'Name',
  custom_identifier: 'Custom Identifier',
  item: 'Item',
  item_description: 'Item Description',
  locker_number: 'Locker Number',
  mode: 'Mode',
  price_required: 'Price Required',
  hardware_type: 'Hardware Type',
  status: 'Status',
  lock_status: 'Lock State',
  mac_address: 'MAC Address',
  integration_id: 'Integration ID',
  locker_udn: 'Locker UDN',
  user_code: 'User Code',
  master_code: 'Master Code',
  gantner_id: 'Gantner ID',
  harbor_tower_id: 'Harbor Tower ID',
  harbor_locker_id: 'Harbor Locker ID',
  dclock_terminal_no: 'DCLOCK Terminal No',
  dclock_box_no: 'DCLOCK Box No',
  ip: 'Ip',
  circuit_unit: 'Circuit Unit',
  board_unit: 'Board Unit',
  hook_port: 'Hook Port',
}
export const ProductColumnValues = (obj: IProduct) => {
  return {
    id: obj.id,
    image: obj.image,
    product_name: obj.name,
    serial_number: obj.serial_number,
    assigned_locker: obj.devices?.[0]?.locker_number,
    assigned_location: obj.devices?.[0]?.location?.name,
    cost: obj.price,
    sale_price: obj.sales_price,
    sku: obj.sku,
    msrp: obj.msrp,
    groups: obj.id_product_group,
  }
}
export const ProductHeaders = [
  'id',
  'image',
  'product_name',
  'serial_number',
  'assigned_locker',
  'assigned_location',
  'cost',
  'sale_price',
  'sku',
  'msrp',
  'groups',
]
export const ProductLabels = {
  id: 'ID',
  image: 'Image',
  product_name: 'Product Name',
  serial_number: 'Serial Number',
  assigned_locker: 'Assigned Locker',
  assigned_location: 'Assigned Location',
  cost: 'Cost',
  sale_price: 'Sale Price',
  sku: 'SKU',
  msrp: 'MSRP',
  groups: 'Groups',
}
export const EventColumnValues = (obj: ITransaction) => {
  return {
    id: obj.id,
    invoice_id: obj.invoice_id,
    order_id: obj.order_id,
    user_name: obj.user.name,
    user_phone: obj.user.phone_number,
    user_email: obj.user.email,
    device_name: obj.device.name,
    locker_number: obj.device.locker_number,
    location: obj.device.location.name,
    mode: obj.device.mode,
    status: obj.device.status,
    start_date: obj.started_at,
    end_date: obj.ended_at,
    duration: obj.ended_at,
    amount: obj.total,
    image: obj.image_url,
  }
}
export const EventHeaders = [
  'id',
  'invoice_id',
  'order_id',
  'user_name',
  'user_phone',
  'user_email',
  'device_name',
  'locker_number',
  'location',
  'mode',
  'status',
  'start_date',
  'end_date',
  'duration',
  'amount',
  'image',
]
export const EventLabels = {
  id: 'ID',
  invoice_id: 'Invoice ID',
  order_id: 'Order ID',
  user_name: 'User Name',
  user_phone: 'User Phone',
  user_email: 'User Email',
  device_name: 'Device Name',
  locker_number: 'Locker Number',
  location: 'Location',
  mode: 'Mode',
  status: 'Status',
  start_date: 'Start Date',
  end_date: 'End Date',
  duration: 'Duration',
  amount: 'Amount',
  image: 'Image',
}
export const SizeColumnValues = (obj: ISize) => {
  return {
    id: obj.id,
    name: obj.name,
    width: obj.width,
    height: obj.height,
    depth: obj.depth,
  }
}
export const SizeHeaders = ['id', 'name', 'width', 'height', 'depth']
export const SizeLabels = {
  id: 'ID',
  name: 'Name',
  width: 'Width',
  height: 'Height',
  depth: 'Depth',
}
export const LocationColumnValues = (obj: ILocation) => {
  return {
    id: obj.id,
    name: obj.name,
    no_of_devices: obj.devices?.length,
    address: replaceCommaWithSpace(obj.address),
    image: obj.image,
    hidden: obj.hidden,
  }
}
export const LocationHeaders = [
  'id',
  'name',
  'no_of_devices',
  'address',
  'image',
  'hidden',
]
export const LocationLabels = {
  id: 'ID',
  name: 'Name',
  no_of_devices: 'No of Devices',
  address: 'Address',
  image: 'Image',
  hidden: 'Hidden',
}
export const ProductGroupColumnValues = (obj: IProductGroup) => {
  return {
    id: obj.id,
    group_name: obj.name,
    locker_size: obj?.size?.name,
    no_of_products: obj.products?.length,
  }
}
export const ProductGroupHeaders = [
  'id',
  'group_name',
  'locker_size',
  'no_of_products',
]
export const ProductGroupLabels = {
  id: 'ID',
  group_name: 'Group Name',
  locker_size: 'Locker Size',
  no_of_products: 'No of Products',
}
export const ConditionColumnValues = (obj: ICondition) => {
  return {
    id: obj.id,
    name: obj.name,
    products_assigned: obj.products?.length,
    auto_report_issue: obj.auto_report,
    auto_maintenance: obj.auto_maintenance,
  }
}
export const ConditionHeaders = [
  'id',
  'name',
  'products_assigned',
  'auto_report_issue',
  'auto_maintenance',
]
export const ConditionLabels = {
  id: 'ID',
  name: 'Name',
  products_assigned: 'Products Assigned',
  auto_report_issue: 'Auto Report Issue',
  auto_maintenance: 'Auto Maintenance',
}
export const UserColumnValues = (obj: IUser) => {
  return {
    id: obj.id,
    name: obj.name,
    last_name: obj.last_name,
    email: obj.email,
    address: replaceCommaWithSpace(obj.address),
    phone_number: obj.phone_number,
    user_id: obj.user_id,
    user_pin_code: obj.pin_code,
    active: obj.active,
    created_at: obj.created_at,
    require_auth: obj.require_auth,
  }
}
export const UserHeaders = [
  'id',
  'name',
  'last_name',
  'email',
  'address',
  'phone_number',
  'user_id',
  'user_pin_code',
  'active',
  'created_at',
  'require_auth',
]
export const UserLabels = {
  id: 'ID',
  name: 'Name',
  last_name: 'Last Name',
  email: 'Email',
  address: 'Address',
  phone_number: 'Phone Number',
  user_id: 'User ID',
  user_pin_code: 'User Pin Code',
  active: 'Active',
  created_at: 'Created At',
  require_auth: 'Require Auth',
}
export const GroupColumnValues = (obj: IGroup) => {
  return {
    id: obj.id,
    name: obj.name,
    users: obj.users?.length,
    devices: obj.devices,
    locations: obj.locations.length,
  }
}
export const GroupHeaders = ['id', 'name', 'users', 'devices', 'locations']
export const GroupLabels = {
  id: 'ID',
  name: 'Name',
  users: 'Users',
  devices: 'Devices',
  locations: 'Locations',
}
export const MemberColumnValues = (obj: IMember) => {
  return {
    user_id: obj.user_id,
    email: obj.email,
    name: obj.name,
    first_name: obj.first_name,
    last_name: obj.last_name,
    role: obj.role,
    pin_code: obj.pin_code,
  }
}
export const MemberHeaders = [
  'user_id',
  'email',
  'name',
  'first_name',
  'last_name',
  'role',
  'pin_code',
]
export const MemberLabels = {
  user_id: 'User ID',
  email: 'Email',
  name: 'Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  role: 'Role',
  pin_code: 'Pin Code',
}

export const ReservationColumnValues = (obj: IReservation) => {
  return {
    id: obj.id,
    tracking_number: obj?.tracking_number,
    location: obj?.location?.name,
    size: obj?.size?.name,
    user: obj?.user?.name,
    device: obj?.device?.name,
    product: obj?.product?.name,
    phone_number: obj?.phone_number,
    email: obj?.email,
    mode: obj?.mode,
    recurring: obj?.recurring,
    monday: obj?.monday,
    tuesday: obj?.tuesday,
    wednesday: obj?.wednesday,
    thursday: obj?.thursday,
    friday: obj?.friday,
    saturday: obj?.saturday,
    sunday: obj?.sunday,
    from_time: obj?.from_time,
    to_time: obj?.to_time,
    start_date: obj?.start_date,
    end_date: obj?.end_date,
    created_at: obj?.created_at,
  }
}
export const ReservationHeaders = [
  'id',
  'tracking_number',
  'location',
  'size',
  'user',
  'device',
  'product',
  'phone_number',
  'email',
  'mode',
  'recurring',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'from_time',
  'to_time',
  'start_date',
  'end_date',
  'created_at',
]
export const ReservationLabels = {
  id: 'ID',
  tracking_number: 'Tracking Number',
  location: 'Location Name',
  size: 'Size Name',
  user: 'User Name',
  device: 'Device Name',
  product: 'Product Name',
  phone_number: 'Phone Number',
  email: 'Email',
  mode: 'Mode',
  recurring: 'Recurring',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  from_time: 'From Time',
  to_time: 'To Time',
  start_date: 'Start Date',
  end_date: 'End Date',
  created_at: 'Created At',
}

export const MembershipColumnValues = (obj: ISubscription) => {
  return {
    id: obj.id,
    name: obj?.name,
    description: obj?.description,
    active: obj?.active,
    currency: obj?.currency,
    amount: obj?.amount,
    billing_type: obj?.billing_type,
    billing_period: obj?.billing_period,
    number_of_payments: obj?.number_of_payments,
    membership_type: obj?.membership_type,
    value: obj?.value,
    stripe_product_id: obj?.stripe_product_id,
    stripe_price_id: obj?.stripe_price_id,
    card_on_file: obj?.card_on_file,
    locations: obj?.locations.length,
    users: obj?.users.length,
  }
}

export const MembershipHeaders = [
  'id',
  'name',
  'description',
  'active',
  'currency',
  'amount',
  'billing_type',
  'billing_period',
  'number_of_payments',
  'membership_type',
  'value',
  'stripe_product_id',
  'stripe_price_id',
  'card_on_file',
  'locations',
  'users',
]

export const MembershipLabels = {
  id: 'ID',
  name: 'Name',
  description: 'Description',
  active: 'Active',
  currency: 'Currency',
  amount: 'Amount',
  billing_type: 'Billing Type',
  billing_period: 'Billing Period',
  number_of_payments: 'Number of Payments',
  membership_type: 'Membership Type',
  value: 'Value',
  stripe_product_id: 'Stripe Product ID',
  stripe_price_id: 'Stripe Price ID',
  card_on_file: 'Card On File',
  locations: 'Locations',
  users: 'Users',
}

export const IssueColumnValues = (obj: IIssue) => {
  return {
    id: obj.id,
    created_at: obj?.created_at,
    description: replaceCommaWithSpace(obj?.description),
    pictures: obj?.pictures,
    status: obj?.status,
    issue_id: obj?.issue_id,
    id_user: obj?.id_user,
    id_event: obj?.id_event,
    team_member_id: obj?.team_member_id,
    team_member: obj?.team_member?.user_id,
    invoice_id: obj?.invoice_id,
  }
}

export const IssueHeaders = [
  'id',
  'created_at',
  'description',
  'pictures',
  'status',
  'issue_id',
  'id_user',
  'id_event',
  'team_member_id',
  'team_member',
  'invoice_id',
]

export const IssueLabels = {
  id: 'ID',
  created_at: 'Created At',
  description: 'Description',
  pictures: 'Pictures',
  status: 'Status',
  issue_id: 'Issue ID',
  id_user: 'User ID',
  id_event: 'Event ID',
  team_member_id: 'Team Member ID',
  team_member: 'Team Member',
  invoice_id: 'Invoice ID',
}
