import { Box, Typography } from '@mui/material'
import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import { IPrice, IPriceList, IPromo, ISubscription } from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { membershipApi, organizationApi, priceApi, promoApi } from 'resources'
import ItemRow from './ItemRow'

import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import { getOrganizationFromHost, generateFilters } from 'utils/helpers'
import { useNavigate } from 'react-router'

import { Toolbar } from 'components/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from '../ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'
import MembershipForm from './MembershipForm'

import { SUBSCRIPTION_DEFAULTS } from '../../constants'

const response = {
  items: [],
  total: 0,
  pages: 0,
}

const Membership = () => {
  const [data, setData] = useState<any>(response)
  const [dataBackup, setDataBackup] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [currentItem, setCurrentItem] = useState<any>()
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [isStripeEnabled, setIsStripeEnabled] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowData, setSelectedRowData] = useState<any[]>([])

  const { loading, setLoading } = useLoadingState(true)
  const { getOrgDetailsPublic } = organizationApi()
  const { dialog, displayMessage, closeDialog } = useDialog()

  const navigate = useNavigate()

  const { filteredColumns, setFilteredColumns } = useColumnFiltering({
    displayMessage,
  })

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'subscriptions',
      nestedProps: [],
    })

  const { getMany: getSubscriptions, remove: deleteMembership } =
    membershipApi()

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    console.log(event, checked, id)
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )

    setSelectedRowData((previousValue) =>
      checked
        ? [...previousValue, data.items.find((row) => row.id === id)]
        : previousValue.filter((row) => row.id !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? data.items.map((device) => device.id) : [],
    )

    setSelectedRowData(() => (checked ? [...data.items] : []))
  }

  const handleDeleteMany = async () => {
    try {
      await deleteMany()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchData = async () => {
    setCurrentPage(DEFAULT_PAGE)
    setRowsPerPage(ITEMS_PER_PAGE)
    fetchSubscriptions()
  }

  const deleteMany = useCallback(async () => {
    await deleteMembership(selectedRows)
    displayMessage(
      `${selectedRows.length} item${
        selectedRows.length > 1 ? 's' : ''
      } deleted successfully`,
      'success',
    )
    fetchSubscriptions()
    closeDialog()
    setSelectedRows([])
  }, [selectedRows])

  const handleClose = () => {
    setOpenForm(false)
    setCurrentItem(undefined)
  }

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const fetchSubscriptions = async (showNewestFirst: boolean = false) => {
    try {
      setLoading(true)
      const data = await getSubscriptions(currentPage + 1, rowsPerPage, search)
      setDataBackup([...data.items])
      setData(data)
      const filters = generateFilters(data.items[0], SUBSCRIPTION_DEFAULTS)
      setFilteredColumns(filters)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenForm = async () => {
    setOpenForm(true)
  }

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(data.items, dataBackup),
    [data, order, orderBy],
  )

  const dialogButtonText = useMemo(
    () => (!isStripeEnabled ? 'Setup Stripe' : 'Ok'),
    [isStripeEnabled],
  )

  const onCloseDialogHandler = useMemo(
    () =>
      !isStripeEnabled
        ? () => {
            navigate('/pricing-set-up')
            closeDialog()
          }
        : closeDialog,
    [isStripeEnabled],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          key={'addDevice'}
          variant="contained"
          name="addDevice"
          onClick={handleOpenForm}
          disabled={loading}
        >
          Add Subscription
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (currentItem) {
      setOpenForm(true)
    }
  }, [currentItem])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchSubscriptions()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    const fetchStripeData = async () => {
      const orgName = getOrganizationFromHost()
      const { stripe_enabled } = await getOrgDetailsPublic(orgName)
      setIsStripeEnabled(stripe_enabled)
    }
    fetchStripeData()
  }, [])

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>

      <MultiSelectToolbar
        show={selectedRows.length > 0}
        itemsSelected={selectedRows.length}
        handleAction={handleDeleteMany}
        actionsAllowed={['export', 'delete']}
        itemsSelectedData={selectedRowData}
        model="subscription"
      />

      <Table loading={loading}>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headers={filteredColumns?.filter((c) => c.active)}
          handleSelectAll={handleSelectAll}
        />
        <TableBody>
          {visibleRows.map((item) => (
            <ItemRow
              key={item.id}
              item={item}
              setCurrentItem={setCurrentItem}
              filteredColumns={filteredColumns}
              displayMessage={displayMessage}
              success={fetchSubscriptions}
              handleSelectRow={handleSelectRow}
              selectedRows={selectedRows}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        totalItems={data.total}
        currentPage={currentPage}
        itemsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setRowsPerPage}
      />

      <SideBar open={openForm} onClose={handleClose}>
        <MembershipForm
          membership={currentItem as ISubscription}
          onClose={handleClose}
          displayMessage={displayMessage}
          success={fetchSubscriptions}
        />
      </SideBar>

      <NotificationDialog
        open={dialog.isOpen}
        onClose={onCloseDialogHandler}
        message={dialog.message}
        buttonText={dialogButtonText}
        type={dialog.type}
      />
    </>
  )
}

export default Membership
