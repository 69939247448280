import { HTTP_CODES } from 'constants/HTTP'
import { INotificationList } from 'models/Notification'
import { DropdownOption } from 'types'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'
import useNotificationApi from 'services/notificationServiceApi'

const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'No notifcations found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal server error has occurred',
}

const notificationApi = () => {
  const { fetchPartnerEndpoint, fetchEndpoint } = useApiRequest()
  const { uploadImagesToNotification } = useNotificationApi()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<INotificationList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `notifications?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (
    notification,
    images: { header?: File; footer?: File; body?: File },
  ) => {
    const endpoint = `notifications`
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(notification),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      const result = await response.json()
      // Step 2: Upload image if file is provided
      if (images.header) {
        try {
          await uploadImagesToNotification({
            notificationId: result.id,
            header_image: images.header || null,
            body_image: images.body || null,
            footer_image: images.footer || null,
          })
        } catch (uploadError) {
          throw new Error('Notification created, but image upload failed.')
        }
      }
      return result
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (
    notificationId,
    notification,
    images: { header?: File; footer?: File; body?: File },
  ) => {
    const endpoint = `notifications/${notificationId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(notification),
        headers,
      })
      const result = await response.json()
      if (!response.ok) {
        throw result.detail
      }
      // Step 2: Upload image if file is provided
      if (images.header) {
        try {
          await uploadImagesToNotification({
            notificationId: result.id,
            header_image: images.header || null,
            body_image: images.body || null,
            footer_image: images.footer || null,
          })
        } catch (uploadError) {
          throw new Error('Notification created, but image upload failed.')
        }
      }
      return result
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]) => {
    const endpoint = `notifications`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getNotificationTypes = async (): Promise<DropdownOption[]> => {
    const endpoint = `types/notification/type`
    const method = 'GET'
    try {
      const response = await fetchEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, update, remove, getNotificationTypes }
}
export default notificationApi
