import { useState } from 'react'
import {
  TableCell,
  TableRow,
  IconButton,
  ConfirmDialog,
  IDialogType,
} from 'components'
import { Checkbox, Link, Tooltip } from '@mui/material'
import { ContentCopy, DeleteForever } from '@mui/icons-material'
import { getUserRole } from 'utils/helpers'
import { ICondition } from 'models/Condition'
import { conditionApi } from 'resources/condition'

interface IConditionRowProps {
  condition: ICondition
  success: () => void
  handleEdit: (condition: ICondition) => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const ConditionRow = ({
  condition,
  success,
  handleEdit,
  filteredColumns,
  displayMessage,
  handleSelectRow,
  selected,
}: IConditionRowProps): React.ReactElement => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const { patch, remove, create } = conditionApi()

  const handleDeleteCondition = async (): Promise<void> => {
    try {
      await remove([condition.id])
      success()
      displayMessage('Location deleted successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleDuplicate = async (): Promise<void> => {
    try {
      await create({
        name: condition.name,
        auto_report: condition.auto_report,
        auto_maintenance: condition.auto_maintenance,
        devices: condition.products.map((device) => device.id),
      })
      displayMessage('Condition duplicated successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleMaintenance = async (): Promise<void> => {
    try {
      await patch(
        {
          auto_maintenance: !condition.auto_maintenance,
        },
        condition.id,
      )
      displayMessage('Saved successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleReport = async (): Promise<void> => {
    try {
      await patch(
        {
          auto_report: !condition.auto_report,
        },
        condition.id,
      )
      displayMessage('Saved successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleConfirmAction = async (action: string) => {
    switch (action) {
      case 'delete':
        await handleDeleteCondition()
        break
      case 'duplicate':
        await handleDuplicate()
        break
      case 'maintenance':
        await handleMaintenance()
        break
      case 'report':
        await handleReport()
        break
      default:
        return
    }
  }

  const role = getUserRole()

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, condition.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'condition' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleEdit(condition)
                }}
              >
                {condition.name}
              </Link>
            ) : (
              <>{condition.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'products_assigned' && c.active,
        ) !== -1 && (
          <TableCell align="center">{condition.products?.length}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'maintenance' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {' '}
            <Checkbox
              name="auto_maintenance"
              checked={condition.auto_maintenance}
              onChange={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message: `Are you sure you want to turn ${
                    condition.auto_maintenance ? 'off' : 'on'
                  } auto maintenance mode for this condition?`,
                  action: 'maintenance',
                })
              }}
            />
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'report_issue' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Checkbox
                name="auto_report"
                checked={condition.auto_report}
                onChange={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to turn ${
                      condition.auto_report ? 'off' : 'on'
                    } auto report issue mode for this condition?`,
                    action: 'report',
                  })
                }}
              />
            ) : (
              <></>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' && (
              <>
                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message:
                        'Are you sure you want to duplicate this condition?',
                      action: 'duplicate',
                    })
                  }}
                >
                  <Tooltip title="Duplicate">
                    <ContentCopy />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message: `Are you sure you want to delete this condition? This cannot be undone.`,
                      action: 'delete',
                    })
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteForever />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </TableCell>
        )}
      </TableRow>

      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={() => handleConfirmAction(confirmationMessage.action)}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ConditionRow
