import {
  Autocomplete,
  Box,
  Checkbox,
  Typography,
  TextField as MUITextField,
  Chip,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { Form, IDialogType, TextField } from 'components'
import { useEffect, useState } from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { IProduct } from 'models/Product'
import { productApi } from 'resources/product'
import { ICondition } from 'models/Condition'
import { conditionApi } from 'resources/condition'
import useLoadingState from 'hooks/useLoadingState'
import { DropdownOption } from 'types'

interface IConditionFormProps {
  condition: ICondition | undefined
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
}

const ConditionForm = ({
  condition,
  onClose,
  displayMessage,
  success,
}: IConditionFormProps) => {
  const [devices, setDevices] = useState<IProduct[]>([])
  const [options, setOptions] = useState<DropdownOption[]>([])
  const [devicesToAdd, setDevicesToAdd] = useState<string[]>([])
  const [devicesToRemove, setDevicesToRemove] = useState<string[]>([])

  const { create, update } = conditionApi()
  const { getMany } = productApi()
  const { loading, setLoading } = useLoadingState()
  const { loading: devicesLoading, setLoading: setDevicesLoading } =
    useLoadingState()

  const methods = useForm<ICondition>({
    defaultValues: {
      ...condition,
      // transaction_number: condition ? condition.transaction_number : 0,
    },
  })

  const fetchDevices = async (): Promise<void> => {
    try {
      const devices = await getMany(1, 10000)
      setDevices(devices?.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const productsSelected: string[] = value.map((option) => option.value)
    console.log(productsSelected)
    setDevicesToAdd(productsSelected)
  }

  const handleSelect = (userId: string) => {
    if (devicesToRemove.includes(userId)) {
      setDevicesToRemove(devicesToRemove.filter((id) => id !== userId))
    } else {
      setDevicesToRemove((prevState) => [...prevState, userId])
    }
  }

  const buildOptions = () => {
    if (devices.length > 0) {
      const notAssignedDevices = devices.filter(
        (device) => !condition?.products.some((d) => d.id === device.id),
      )
      const autoCompleteOptions = notAssignedDevices.map((device) => ({
        label: device.name,
        value: device.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
    setDevicesLoading(false)
  }

  const onSubmit: SubmitHandler<ICondition> = async (
    newCondition: ICondition,
  ) => {
    try {
      setLoading(true)
      if (condition) {
        const currentDevices = condition.products.map((device) => device.id)
        const newDevices = [
          ...currentDevices.filter((id) => !devicesToRemove.includes(id)),
          ...devicesToAdd,
        ]
        await update(
          {
            ...newCondition,
            products: newDevices,
          },
          newCondition.id,
        )
        onClose()
        displayMessage('Condition updated successfully!', 'success')
        success()
      } else {
        await create({
          ...newCondition,
          products: devicesToAdd,
        })
        onClose()
        displayMessage('Condition created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDevices()
  }, [])

  useEffect(() => {
    setDevicesLoading(true)
    buildOptions()
  }, [devices])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper
            helpText="Conditions allow the user to report the condition of the product either after or before use"
            title={condition ? 'Edit Condition' : 'Add Condition'}
          >
            <TextField
              name="name"
              label="Condition Name"
              placeholder="Condition Name"
              rules={{
                required: 'Condition name is required',
              }}
            />

            <Autocomplete
              multiple
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Assign products..." />
              )}
              onChange={handleToggle}
              loading={devicesLoading}
            />

            {condition && (
              <>
                <Typography variant="h5">
                  Products assigned to condition
                </Typography>
                <Typography variant="caption" color="rgb(173, 176, 187)">
                  {condition.products.length > 0
                    ? 'Select the products that you want to remove from this condition and hit submit'
                    : 'No products assigned to this condition'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '0.75rem',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {condition.products.length > 0 &&
                    condition.products.map((device) => (
                      <Chip
                        key={device.id}
                        label={`${device.name}`}
                        onClick={() => handleSelect(device.id)}
                        onDelete={() => handleSelect(device.id)}
                        deleteIcon={
                          devicesToRemove.includes(device.id) ? (
                            <DoneIcon />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        variant={
                          devicesToRemove.includes(device.id)
                            ? 'filled'
                            : 'outlined'
                        }
                        color={
                          devicesToRemove.includes(device.id)
                            ? 'primary'
                            : 'default'
                        }
                      />
                    ))}
                </Box>
              </>
            )}

            <FormControl sx={{ width: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register('auto_report')}
                    defaultChecked={condition?.auto_report || false}
                  />
                }
                label="Automatically reports issue"
              />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register('auto_maintenance')}
                    defaultChecked={condition?.auto_maintenance || false}
                  />
                }
                label="Automatically goes into maintenance"
              />
            </FormControl>
            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}
export default ConditionForm
