export const RETURN_TO_OPTIONS = [
  {
    value: 'one_to_one',
    label: 'Same Locker',
  },
  {
    value: 'any_locker_of_same_size_and_location',
    label: 'Any locker of same size and location',
  },
  // {
  //   value: 'any_locker_of_same_size_at_any_location',
  //   label: 'Any locker of same size at any location',
  // },
]

export const PRODUCT_DEFAULTS = [
  'select',
  'image',
  'product_name',
  'serial_number',
  'assigned_locker',
  'assigned_location',
  'cost',
  'sale_price',
  'sku',
  'msrp',
  'groups',
  'qr_code',
  'barcode',
  'action',
]

export const PRODUCT_SHORT_DEFAULTS = [
  'product_name',
  'assigned_locker',
  'assigned_location',
  'cost',
  'sale_price',
  'sku',
  'msrp',
]
