import { Box, Divider, Switch, Typography } from '@mui/material'
import Filters from 'components/Toolbar/components/Filters'
import { SearchBar } from 'components/SearchBar'
import { useEffect } from 'react'

import { ChangeEvent, PropsWithChildren } from 'react'

interface IToolbarSearchbarProps {
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void
  filteredColumns?: any
  setFilteredColumns?: React.Dispatch<React.SetStateAction<string[]>> | any
  enableFilters?: boolean
}
const storageKey = 'filteredColumns_transactions'

const ToolbarSearchbar = ({
  handleSearch,
  filteredColumns,
  setFilteredColumns,
  enableFilters = true,
  children,
}: PropsWithChildren<IToolbarSearchbarProps>) => {
  const handleToggle = async (event, column) => {
    const toggled = event.target.checked
    const index = filteredColumns?.findIndex((c) => c.value === column.value)

    if (index !== -1) {
      const activeColumnsClone = [...filteredColumns]
      activeColumnsClone[index] = { ...column, active: toggled }
      setFilteredColumns(activeColumnsClone)
      localStorage.setItem(storageKey, JSON.stringify(activeColumnsClone))
    }
  }

  useEffect(() => {
    const storedColumns = localStorage.getItem(storageKey)
    if (storedColumns) {
      const parsedColumns = JSON.parse(storedColumns)
      setFilteredColumns(parsedColumns)
    }
  }, [setFilteredColumns])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <SearchBar onSearch={handleSearch} />
      {enableFilters && (
        <Filters>
          {filteredColumns && (
            <>
              <Typography width="350px" padding="10px">
                Toggle to enable or disable columns that you want to filter the
                table by.
              </Typography>
              {filteredColumns.map((column, index) => (
                <Box key={column.value}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '350px',
                      padding: '10px',
                    }}
                  >
                    <Typography>{column.label}</Typography>
                    <Switch
                      checked={column.active}
                      defaultChecked={column.active}
                      onChange={(event) => handleToggle(event, column)}
                    />
                  </Box>
                  {index !== filteredColumns.length - 1 && <Divider light />}
                </Box>
              ))}
            </>
          )}
          {!filteredColumns && children}
        </Filters>
      )}
    </Box>
  )
}

export default ToolbarSearchbar
