export const GOOGLE_API_KEY = process?.env?.REACT_APP_GOOGLE_API_KEY
export const API_URL = process?.env?.REACT_APP_API_URL
export const NOTIFICATION_API_URL = process?.env?.REACT_APP_NOTIFICATION_API_URL
export const RENTAL_URL = process?.env?.REACT_APP_RENTAL_URL
export const PUBLIC_API_URL = process?.env?.REACT_APP_PUBLIC_API_URL
export const AWS_PROJECT_REGION = 'us-east-1'
export const AWS_COGNITO_REGION = 'us-east-1'
export const HOST = window.location.hostname
export const TWILIO_CONNECT_APP_SID =
  process?.env?.REACT_APP_TWILIO_CONNECT_APP_SID

export const PROJECT_VERSION = '1.0'
