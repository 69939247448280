import { Grid, Box, Typography, Button, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import PageContainer from '../../components/container/PageContainer'
import img1 from '../../assets/images/backgrounds/login-bg.png'

const SignUp = () => (
  <PageContainer title="Register" description="this is Register page">
    <Grid
      container
      spacing={0}
      sx={{ height: '100vh', justifyContent: 'center' }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        lg={6}
        sx={{
          background: (theme) =>
            `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: {
                xs: 'relative',
                lg: 'absolute',
              },
              height: { xs: 'auto', lg: '100vh' },
              right: { xs: 'auto' },
              margin: '0 auto',
            }}
          >
            <img
              src={img1}
              alt="bg"
              style={{
                width: '100%',
                maxWidth: '812px',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
        <Grid container spacing={0} display="flex" justifyContent="center">
          <Grid item xs={12} lg={9} xl={6}>
            <Box
              sx={{
                p: 4,
              }}
            >
              <Typography fontWeight="700" variant="h2">
                Welcome
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  color="textSecondary"
                  variant="h6"
                  fontWeight="400"
                  sx={{
                    mr: 1,
                  }}
                >
                  Already have an Account?
                </Typography>
                <Typography
                  component={Link}
                  to="/auth/login"
                  fontWeight="500"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    color: 'primary.main',
                  }}
                >
                  Sign In
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 4,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: '5px',
                    marginTop: '15px',
                    display: 'block',
                  }}
                  component="label"
                  htmlFor="name"
                >
                  Name
                </Typography>
                <TextField id="name" variant="outlined" fullWidth />
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: '5px',
                    marginTop: '15px',
                    display: 'block',
                  }}
                  component="label"
                  htmlFor="email"
                >
                  Email Address
                </Typography>
                <TextField id="email" variant="outlined" fullWidth />
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: '5px',
                    marginTop: '15px',
                    display: 'block',
                  }}
                  component="label"
                  htmlFor="password"
                >
                  Password
                </Typography>
                <TextField
                  id="password"
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 3,
                  }}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  fullWidth
                  component={Link}
                  to="/"
                  sx={{
                    pt: '10px',
                    pb: '10px',
                  }}
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </PageContainer>
)

export default SignUp
