import { Box, Typography, TextField as MUITextField } from '@mui/material'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Dialog, Form, IDialogType } from 'components'
import { ITransaction } from 'models'
import { transactionsApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { capitalizeFirstLetter } from 'utils/helpers'

interface IChargeDialogProps {
  transaction: ITransaction
  open: boolean
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const ChargeDialog = ({
  transaction,
  open,
  onClose,
  displayMessage,
  success,
}: IChargeDialogProps) => {
  const { loading, setLoading } = useLoadingState()
  const { service } = transactionsApi()
  const hasUnit = !!transaction.device?.price?.unit

  const onCharge = async (weight?: number): Promise<void> => {
    try {
      setLoading(true)
      /*
        API requires weight greater than 0, so if the weight is 0, we will use 1 as default as
        the transaction is free and we just need to update the status to charged and keep consistency 
        based on product requirements.

        TODO: This should be handled in the backend to avoid this workaround.
      */
      const weight_value = weight ? weight : 1
      await service({
        weight: weight_value,
        step: 'charge',
        transactionId: transaction.id,
      })
      success()
      displayMessage('Transaction charged successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<{ weight?: number }> = (formData): void => {
    if (hasUnit) {
      onCharge(formData.weight)
    } else {
      onCharge()
    }
    onClose()
  }

  const methods = useForm<{ weight?: number }>()

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Charge" isDialog={true}>
            {hasUnit ? (
              <>
                <Typography
                  textAlign="center"
                  color="text.secondary"
                  variant="h5"
                >
                  Total Weight
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <MUITextField
                    {...methods.register('weight')}
                    sx={{ width: '85%' }}
                    name="weight"
                    label="Total Weight"
                  />
                  <Box sx={{ width: '15%' }}>
                    {transaction.device?.price?.unit
                      ? capitalizeFirstLetter(transaction.device?.price?.unit)
                      : 'Free'}
                  </Box>
                </Box>
              </>
            ) : (
              <Typography
                textAlign="center"
                color="text.secondary"
                variant="h5"
                sx={{ mt: 2 }}
              >
                This transaction is free!
              </Typography>
            )}

            <FormActions
              submitText="Charge"
              onClose={onClose}
              loading={loading}
              disableSubmit={hasUnit && !methods.watch('weight')}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
export default ChargeDialog
