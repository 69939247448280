import { Box, Divider, Typography } from '@mui/material'
import { ITransaction } from 'models'
import { parseCurrency } from 'utils/helpers'
import { format } from 'date-fns'

interface IUserHistoryItem {
  transaction: ITransaction
}

interface IHistoryLine {
  label: string
  value: string | number | null | undefined
}

const HistoryLine = ({ label, value }: IHistoryLine) => (
  <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    <Typography fontSize={12}>{label}</Typography>
    <Typography fontSize={12} color="text.secondary">
      {value !== null && value !== undefined ? value : 'N/A'}
    </Typography>
  </Box>
)

const UserHistoryItem = ({ transaction }: IUserHistoryItem) => {
  const { started_at, ended_at, device, total_time, total } = transaction

  const formattedStartDate = started_at
    ? format(new Date(started_at), 'MM/dd/yyyy hh:mm a')
    : 'Date not available'

  const formattedEndDate = ended_at
    ? format(new Date(ended_at), 'MM/dd/yyyy hh:mm a')
    : 'Date not available'

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.25rem',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        marginTop: '1rem',
      }}
    >
      <HistoryLine label="Transaction Start Time:" value={formattedStartDate} />
      <HistoryLine label="Transaction End Time:" value={formattedEndDate} />
      <HistoryLine
        label="Product Condition:"
        value={device?.product?.condition?.name || 'N/A'}
      />
      <HistoryLine label="Location:" value={device?.location?.name || 'N/A'} />
      <HistoryLine
        label="Locker Number:"
        value={device?.locker_number ? String(device.locker_number) : 'N/A'}
      />
      <HistoryLine label="Duration:" value={total_time || 'N/A'} />
      <HistoryLine
        label="Amount:"
        value={
          total !== undefined && total !== null
            ? `${parseCurrency(transaction.device?.price?.currency || 'USD')}${
                total === 0 ? '0' : total
              }`
            : 'N/A'
        }
      />
      <Divider sx={{ marginTop: '1rem' }} />
    </Box>
  )
}

export default UserHistoryItem
