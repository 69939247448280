import { useState } from 'react'
import {
  Link,
  IconButton,
  Box,
  Tooltip,
  Checkbox,
  Typography,
} from '@mui/material'
import { DeleteForever, QrCode } from '@mui/icons-material'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import PersonIcon from '@mui/icons-material/Person'
import {
  TableCell,
  TableRow,
  ConfirmDialog,
  QRGenerator,
  IDialogType,
} from 'components'
import { IUser } from 'models'
import { userApi } from '../../../../resources/user'
import { getUserRole } from 'utils/helpers'
import { HOST } from '../../../../constants'

const role = getUserRole()

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}
interface IUserRowProps {
  user: IUser
  onEdit: (user: IUser) => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
  filteredColumns: any[]
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const UserRow = ({
  onEdit,
  user,
  displayMessage,
  success,
  filteredColumns,
  handleSelectRow,
  selected,
}: IUserRowProps): React.ReactElement => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const { deleteUser, updateUser } = userApi()
  const { generateQRCode } = QRGenerator()

  const handleDelete = async (): Promise<void> => {
    try {
      await deleteUser([user.id])
      displayMessage('User deleted successfully.', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const activateUser = async (): Promise<void> => {
    try {
      await updateUser(user.id, { ...user, active: true })
      displayMessage('User has been activated.', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const deactivateUser = async (): Promise<void> => {
    try {
      await updateUser(user.id, { ...user, active: false })
      displayMessage('User has been deactivated.', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleEdit = (): void => {
    onEdit(user)
  }

  const handleConfirm = (): void => {
    try {
      if (confirmationMessage.action === 'activate') {
        activateUser()
      } else if (confirmationMessage.action === 'deactivate') {
        deactivateUser()
      } else {
        handleDelete()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleQRCode = (): void => {
    generateQRCode(user.id, `${HOST}/users/${user.id}/details`)
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, user.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleEdit}
              >
                {user.name || ''}
              </Link>
            ) : (
              <>{user.name || ''}</>
            )}
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'last_name' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleEdit}
              >
                {user.last_name || ''}
              </Link>
            ) : (
              <>{user.last_name || ''}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'email' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleEdit}
              >
                {user.email || ''}
              </Link>
            ) : (
              <>{user.email || ''}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'address' && c.active) !==
          -1 && <TableCell align="center">{user.address || ''}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'phone_number' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleEdit}
              >
                {user.phone_number || ''}
              </Link>
            ) : (
              <>{user.phone_number || ''}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'user_id' && c.active) !==
          -1 && <TableCell align="center">{user.user_id}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'pin_code' && c.active,
        ) !== -1 && <TableCell align="center">{user.pin_code}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'qr_code' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={handleQRCode}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <Tooltip title="QR code">
                <QrCode />
              </Tooltip>
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {role === 'admin' && (
                <Tooltip
                  title={`${user.active ? 'Deactivate' : 'Activate'} user`}
                >
                  <IconButton
                    onClick={() =>
                      setConfirmationMessage({
                        isOpen: true,
                        message: `Are you sure that you want to ${
                          user.active ? 'deactivate' : 'activate'
                        } ${
                          user.name
                            ? `${user.name} (${
                                user.email || user.phone_number
                              })`
                            : `${user.email || user.phone_number}`
                        }?`,
                        action: user.active ? 'deactivate' : 'activate',
                      })
                    }
                  >
                    {user.active ? <PersonOffIcon /> : <PersonIcon />}
                  </IconButton>
                </Tooltip>
              )}
              {role === 'admin' && (
                <IconButton
                  onClick={() =>
                    setConfirmationMessage({
                      isOpen: true,
                      message:
                        'Are you sure that you want to delete this user?',
                      action: 'delete',
                    })
                  }
                >
                  <DeleteForever />
                </IconButton>
              )}
            </Box>
          </TableCell>
        )}
      </TableRow>

      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={handleConfirm}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default UserRow
